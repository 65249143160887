/* eslint-disable complexity */
/* eslint-disable max-len */
import { Alert } from '../../shared/components/Alert/Alert';
import { IEquipmentResponse } from '../../types/Equipment';
import {
    ITEM_WEAR,
    STEAM_WEAPON_WEAR,
    WEAPON_TYPE,
} from '../../types/enums';
import { setBalance } from '../../ducks/userSlice';
import { useAppDispatch } from '../../hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button';
import Coin from '../../shared/components/Coin';
import ConfirmDialog from '../../shared/components/ConfirmDialog';
import Divider from '../../shared/components/Divider';
import EquipmentSkinPreview from '../../shared/components/EquipmentSkinPreview';
import LinearProgress from '@mui/material/LinearProgress';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import axios from 'axios';
import classNames from 'classnames';
import dayjs from 'dayjs';
import getWear from '../../shared/helpers/getWear';
import styles from './Equipment.module.scss';
import weaponBasicData from '../../shared/weaponBasicData';

const Equipment = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [selectedItemId, setSelectedItemId] = useState('');
    const [selledItemName, setSelledItemName] = useState('');
    const [openAlert, setOpenAlert] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

    const [items, setItems] = useState<IEquipmentResponse[]>([]);
    const [, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        axios.get('/api/get-equipment').then(({ data }) => {
            setItems(data.items);
            setSelectedItemId(data.items[0]._id);
            setLoading(false);
        });
    }, []);

    const selectedItem = items.find(item => item._id === selectedItemId);

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const sellItem = useCallback((id: string) => {
        setLoading(true);
        axios.post('/api/sell-items', { items: [id] }).then(({ data }) => {
            setItems(data.items);
            setSelledItemName(selectedItem?.name || '');
            data.items[0]._id && setSelectedItemId(data.items[0]._id);
            dispatch(setBalance(data.balance));
            setLoading(false);
            setIsConfirmModalOpen(false);
            setOpenAlert(true);
        });
    }, [dispatch, selectedItem]);

    const handleSubmitConfirmModal = () => {
        sellItem(selectedItemId);
    };

    const getProgressColor = useCallback(
        (float: number): string => {
            const wear = getWear(float);
            const colorForWear = {
                [ITEM_WEAR.FACTORY_NEW]: 'success',
                [ITEM_WEAR.MINIMAL_WEAR]: 'warning',
                [ITEM_WEAR.FIELD_TESTED]: 'info',
                [ITEM_WEAR.WELL_WORN]: 'secondary',
                [ITEM_WEAR.BATTLE_SCARRED]: 'error',
            };

            return colorForWear[wear];
        },
        [],
    );

    return (
        <div className={styles.wrapper}>
            <ConfirmDialog
                acceptButtonTitle="Sprzedaj"
                content={`Czy na pewno chcesz sprzedac przedmiot: ${selectedItem?.name}?`}
                onSubmit={handleSubmitConfirmModal}
                open={isConfirmModalOpen}
                setOpen={setIsConfirmModalOpen}
                title="Sprzedaj przedmiot"
            />
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                autoHideDuration={2000}
                onClose={handleCloseAlert}
                open={openAlert}
            >
                <Alert onClose={handleCloseAlert} severity="success" sx={{ width: '100%' }}>
                    {`Przedmiot ${selledItemName} został sprzedany`}
                </Alert>
            </Snackbar>
            <Button name="POWRÓT" onClick={() => navigate('/')} />
            <Divider name="EKWIPUNEK" nameColor="#fff" />
            {items.length > 0 ?
                (
                    <div className={styles.columns}>
                        <div className={styles.firstColumn}>
                            <div className={styles.equipmentWrapper}>
                                {items.map((item) => (
                                    <div key={item._id} onClick={() => setSelectedItemId(item._id)}>
                                        <EquipmentSkinPreview
                                            {...item}
                                            id={item._id}
                                            isStatTrack={item.statTrack}
                                            selected={item._id === selectedItemId}
                                            weaponType={item?.weaponType as WEAPON_TYPE}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={styles.secoundColumn}>
                            <div className={styles.secoundColumn}>
                                <div className={classNames(styles.previewWrapper, selectedItem?.statTrack ? styles.statTrackBorder : '')}>
                                    {selectedItem &&
                                                <EquipmentSkinPreview
                                                    noAnimation
                                                    {...selectedItem}
                                                    id={selectedItem._id}
                                                    isNameVisible={false}
                                                    isPriceVisible={false}
                                                    isStatTrack={false}
                                                    weaponType={selectedItem.weaponType as WEAPON_TYPE}
                                                />
                                    }
                                    <div className={classNames(styles.previewTitle, selectedItem?.statTrack ? styles.statTrackColor : '' )}>
                                        {`${selectedItem?.name} ${selectedItem?.statTrack ? '(StatTrak™)' : ''}`}
                                    </div>
                                    <div className={styles.previewDivider} />
                                    <div className={styles.previewDetails}>
                                        <div className={styles.detailsInfo}>{`Typ broni: ${selectedItem ? weaponBasicData[selectedItem?.weaponType].name.en : ''}`}</div>
                                        <div className={styles.detailsInfo} style={{ display: 'flex' }}>
                                            <div style={{ marginRight: '2px' }}>{`Cena: ${selectedItem?.price}`}</div>
                                            <Coin color="#e4ac03" height="18px" width="18px" />
                                        </div>
                                        <div className={styles.detailsInfo}>{`Jakosc: ${selectedItem ? decodeURI(STEAM_WEAPON_WEAR[selectedItem.wear]) : ''}`}</div>
                                        <div className={styles.detailsInfo}>{`Zuzycie: ${selectedItem ? selectedItem.float : ''}`}</div>
                                        <div className={styles.detailsInfo}>
                                            {selectedItem &&
                                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        <LinearProgress color={getProgressColor(selectedItem.float) as any} value={100 - (selectedItem.float * 100)} variant="determinate" />
                                            }
                                        </div>
                                        <div className={styles.detailsInfo}>{`Zdobyte: ${dayjs(selectedItem?.droppedAt).format('DD.MM.YYYY HH:mm')}`}</div>
                                        <div className={styles.detailsInfo}>{`Pattern: ${selectedItem?.pattern}`}</div>
                                        <div className={styles.sellBtnWrapper}><Button name="SPRZEDAJ" onClick={() => setIsConfirmModalOpen(true)} /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={styles.emptyEquipment}>Twój ekwipunek jest pusty</div>
                )}
        </div>
    );
};

export default Equipment;
