/* eslint-disable max-len */
import { ICareer } from '../../types/Career';
import { IUser } from '../../types/User';
import { STEAM_AVATAR_URL } from '../../shared/common';
import {
    selectLogedUser,
    selectUserBalance,
} from '../../ducks/userSlice';
import { useAppSelector } from '../../hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button';
import Coin from '../../shared/components/Coin';
import Divider from '../../shared/components/Divider';
import React, {
    useEffect,
    useState,
} from 'react';
import axios from 'axios';
import styles from './MyAccount.module.scss';

const MyAccount = () => {
    const navigate = useNavigate();
    const user = useAppSelector(selectLogedUser) || {} as IUser;
    const userCoins = useAppSelector(selectUserBalance);
    const [, setCareer] = useState<ICareer>();

    useEffect(
        () => {
            axios
                .get('/api/my-career')
                .then((res) => {
                    setCareer(res.data);
                })
                .catch(() => {
                    console.error('Something went wrong');
                });
        },
        [],
    );

    return (
        <div className={styles.wrapper}>
            <Button name="POWRÓT" onClick={() => navigate('/')} />
            <Divider name="MOJE KONTO" />
            <div className={styles.columns}>
                <div className={styles.column} style={{ display: 'flex' }}>
                    <div className={styles.imgWrapper}>
                        <img alt="user-img" src={`${STEAM_AVATAR_URL}${user.avatarHash}_full.jpg`} />
                    </div>
                    <div style={{ fontFamily: 'stratumBold', margin: '10px' }}>
                        <div style={{ fontSize: '24px', marginBottom: '10px' }}>
                            { user.displayName ? user.displayName.toUpperCase() : ''}
                        </div>
                        <div style={{ display: 'flex', fontSize: '16px' }}>
                            <div style={{ marginRight: '8px' }}>Vip: </div>
                            <div>{user.vipValidTo ? user.vipValidTo : '31.09.2023'}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.achivments}>
                        <div style={{ marginBottom: '10px' }}>KARIERA</div>
                    </div>
                </div>
                <div className={styles.column}>
                    <div className={styles.balans}>
                        <div style={{ marginBottom: '10px' }}>PORTFEL</div>
                        <div style={{ display: 'flex', fontSize: '16px', marginBottom: '5px' }}>
                            <div style={{ marginRight: '8px' }}>Coins:</div>
                            <div>{userCoins > 0 ? `${userCoins}` : '0'}</div>
                            <div style={{ marginLeft: '5px' }}>
                                <Coin color="#e4ac03" height="18px" width="18px" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyAccount;
