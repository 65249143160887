import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './MenuLeft.module.scss';

interface IMenuLeft {
    page: number;
    setPage: (page: number) => void;
}

const MenuLeft: FC<IMenuLeft> = ({ page, setPage }) => {

    return (
        <div className={styles.wrapper}>
            <div
                className={classNames(styles.menuItemWrapper, page === 1 && styles.active)}
                onClick={() => setPage(1)}
            >
                Szablony skinów
            </div>
            <div
                className={classNames(styles.menuItemWrapper, page === 0 && styles.active)}
                onClick={() => setPage(0)}
            >
                Skrzynki
            </div>
            <div
                className={classNames(styles.menuItemWrapper, page === 2 && styles.active)}
                onClick={() => setPage(2)}
            >
                VIPy
            </div>
            <div
                className={classNames(styles.menuItemWrapper, page === 3 && styles.active)}
                onClick={() => setPage(3)}
            >
                Role
            </div>
            <div
                className={classNames(styles.menuItemWrapper, page === 4 && styles.active)}
                onClick={() => setPage(4)}
            >
                Kategorie skrzynek
            </div>
        </div>
    );
};

export default MenuLeft;
