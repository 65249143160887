import {
    Route,
    Routes,
} from 'react-router-dom';
import AccountPanel from './components/AccountPanel';
import AdministrationPanel from './components/AdministrationPanel';
import ChestDetails from './components/ChestDetails';
import Chests from './components/Chests';
import Footer from './components/Footer';
// import Home from './components/Home';
import NavBar from './components/NavBar';
import React from 'react';
import SkinDetails from './shared/components/SkinDetails';
import Upgrader from './components/Upgrader';
import Wiki from './components/Wiki';
import styles from './App.module.scss';

const App = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.navbarWrapper}>
                <NavBar />
            </div>
            <div className={styles.contentWrapper}>
                <Routes>
                    <Route element={<Chests />} path="/" />
                    <Route element={<Chests />} path="/chests" />
                    <Route element={<ChestDetails />} path="/chests/:caseId" />
                    <Route
                        element={<SkinDetails />}
                        path="/chests/:caseId/item/:itemId"
                    />
                    <Route
                        element={<AdministrationPanel />}
                        path="/administration-panel"
                    />
                    <Route element={<Wiki />} path="/wiki" />
                    <Route element={<AccountPanel />} path="/panel/*" />
                    <Route element={<Upgrader />} path="/upgrader" />
                </Routes>
            </div>

            <div className={styles.footerWrapper}>
                <Footer />
            </div>
        </div>
    );
};

export default App;
