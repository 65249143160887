import { useNavigate } from 'react-router-dom';
import Logo from '../../shared/components/Logo/Logo';
import React from 'react';
import styles from './Footer.module.scss';

const Footer = () => {
    const navigate = useNavigate();

    return (
        <footer className={styles.footerWrapper}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
                <div className={styles.footerLogo} onClick={() => navigate('/')}>
                    <Logo />
                </div>
                <button className={styles.footerButton} style={{ marginLeft: '10px' }}>GO-DROP</button>
                <button className={styles.footerButton}>ABOUT</button>
                <button className={styles.footerButton}>INFORMATIONS</button>
                <button className={styles.footerButton}>SUPPORT</button>
            </div>
            <div>
                © 2023 GO-DROP ALL RIGHTS RESERVED.
            </div>
        </footer>
    );
};

export default Footer;
