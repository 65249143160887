import React from 'react';
import styles from './Divider.module.scss';

interface IProps {
    font?: string;
    name: string;
    nameColor?: string;
}

const Divider = ({ font = 'stratumBold', name, nameColor = '#fff' }: IProps) => {
    return (
        <div
            className={styles.dividerWrapper}
            style={{ color: nameColor, fontFamily: font ? font : '', fontSize: '22px' }}
        >
            {name}
        </div>
    );
};

export default Divider;
