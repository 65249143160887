import React from 'react';
import styles from './Button.module.scss';

interface IProps {
    bgColor?: string;
    name: string;
    onClick: () => void;
}

const Button = ({ bgColor, name, onClick }: IProps) => {
    return (
        <button
            className={styles.btn}
            onClick={onClick}
            style={{ backgroundColor: bgColor }}
            type="button"
        >
            <span>
                {name}
            </span>
        </button>
    );
};

export default Button;
