/* eslint-disable max-len */
import Pie from './Pie/Pie';
import React from 'react';
import styles from './Upgrader.module.scss';

const Upgrader = () => {

    return (
        <div className={styles.wrapper}>
            <Pie />
        </div>
    );
};

export default Upgrader;
