import { useNavigate } from 'react-router-dom';
import Button from '../../shared/components/Button';
import Divider from '../../shared/components/Divider';
import React from 'react';
import styles from './Settings.module.scss';

const Settings = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.wrapper}>
            <Button name="POWRÓT" onClick={() => navigate('/')} />
            <Divider name="USTAWIENIA" />
        </div>
    );
};

export default Settings;
