import { IChest } from '../../../types/chest';
import Coin from '../Coin';
import React, { useState } from 'react';
import styles from './ChestPreview.module.scss';

interface IChestPreview {
    chest: IChest;
    onChestClick: () => void;
}

const ChestPreview = ({
    chest,
    onChestClick,
}: IChestPreview) => {
    const [itemImageLoaded, setItemImageLoaded] = useState(false);
    const [chestImageLoaded, setChestImageLoaded] = useState(false);

    return (
        <div
            className={styles.wrapper}
            onClick={onChestClick}
        >
            <div className={styles.image}>
                <img
                    alt={chest.name}
                    onLoad={() => setItemImageLoaded(true)}
                    src={chest.icon}
                    style={itemImageLoaded ? {} : { display: 'none' }}
                />
            </div>
            <div className={styles.info}>
                <div className={styles.name}>{chest.name}</div>
                <div className={styles.price}>
                    {chest.price}<Coin />
                </div>
            </div>
            <div className={styles.itemImage}>
                <img
                    alt="img"
                    draggable={false}
                    onLoad={() => setChestImageLoaded(true)}
                    src={chest.bestWeaponIcon}
                    style={chestImageLoaded ? {} : { display: 'none' }}
                />
            </div>
        </div>
    );
};

export default ChestPreview;
