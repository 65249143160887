import * as React from 'react';
import { styled } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Backpack from '@mui/icons-material/Backpack';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import FolderSpecial from '@mui/icons-material/FolderSpecial';
import SettingsIcon from '@mui/icons-material/Settings';

interface IProps {
    setValue: React.Dispatch<React.SetStateAction<number>>;
    value: number;
}

const BottomNavigationComponent = ({ setValue, value }: IProps) => {
    const StyledBottomNavigation = styled(BottomNavigationAction)({
        '& .Mui-selected': {
            color: '#fff',
        },
        '& .MuiSvgIcon-root': {
            color: '#fff',
        },
    });

    return (
        <Box sx={{ width: 500 }}>
            <BottomNavigation
                onChange={(event, newValue) => {
                    setValue(newValue);
                }}
                showLabels
                style={{ color: 'rgba(255, 255, 255, 0.7)' }}
                value={value}
            >
                <StyledBottomNavigation icon={<AccountCircleIcon />} label="Moje konto" />
                <StyledBottomNavigation icon={<Backpack />} label="Ekwipunek" />
                <StyledBottomNavigation icon={<FolderSpecial />} label="Wyposażenie" />
                <StyledBottomNavigation icon={<SettingsIcon />} label="Ustawienia" />
            </BottomNavigation>
        </Box>
    );
};

export default BottomNavigationComponent;
