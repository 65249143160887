import Chests from './components/Chests';
import ChestsGroups from './components/ChestsGroups/ChestsGroups';
import MenuLeft from './components/MenuLeft';
import React, {
    useMemo,
    useState,
} from 'react';
import Roles from './components/Roles';
import Templates from './components/Templates';
import Vips from './components/Vips';
import styles from './AdministrationPanel.module.scss';

const AdministrationPanel = () => {
    const [page, setPage] = useState(1);

    const PageComponent = useMemo(
        () => [
            <Chests key={0} />,
            <Templates key={1} />,
            <Vips key={2} />,
            <Roles key={3} />,
            <ChestsGroups key={4} />,
        ][page],
        [page],
    );

    return (
        <div className={styles.wrapper}>
            <div className={styles.leftMenuWrapper}>
                <MenuLeft
                    page={page}
                    setPage={setPage}
                />
            </div>
            <div className={styles.contentWrapper}>
                {PageComponent}
            </div>
        </div>
    );
};

export default AdministrationPanel;
