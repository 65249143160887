/* eslint-disable max-len */
import { ROLE } from '../../types/enums';
import { STEAM_AVATAR_URL } from '../../shared/common';
import {
    getUser,
    selectLogedUser,
} from '../../ducks/userSlice';
import {
    useAppDispatch,
    useAppSelector,
} from '../../hooks/useRedux';
import { useNavigate } from 'react-router-dom';
import Coin from '../../shared/components/Coin';
import Logo from '../../shared/components/Logo/Logo';
import LogoutIcon from '../../assets/icons/logout';
import React, { useEffect } from 'react';
import styles from './NavBar.module.scss';

const NavBar = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectLogedUser) || {};
    const isUserLogged = !!user.steamId;
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getUser());
    }, [dispatch]);

    const authUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:8000/api/auth/steam'
        : 'https://godrop.pl/api/auth/steam';

    return (
        <div className={styles.navbar}>
            <div style={{ alignItems: 'center', display: 'flex' }}>
                <div className={styles.navbarLogo} onClick={() => navigate('/')}>
                    <Logo />
                </div>
                <button className={styles.navButton} onClick={() => navigate('/')} style={{ marginLeft: '10px' }}>GO-DROP</button>
                <button className={styles.navButton} onClick={() => navigate('/chests')}>SKRZYNKI</button>
                <button className={styles.navButton} onClick={() => navigate('/panel/equipment')}>EKWIPUNEK</button>
                {user.role === ROLE.ADMIN && <button className={styles.navButton} onClick={() => navigate('/wiki')}>WIKI</button>}
                {user.role === ROLE.ADMIN && <button className={styles.navButton} onClick={() => navigate('/administration-panel')}>PANEL ADMINISTRATORA</button>}
            </div>
            <div style={{ alignItems: 'center', display: 'flex' }}>
                <div className={styles.wallet}>
                    <div style={{ marginRight: '5px' }}>COINS:</div>
                    <div style={{ marginRight: '2px' }}>{user.balance}</div>
                    <Coin color="#e4ac03" height="18px" width="18px" />
                </div>
                {!isUserLogged ? (
                    <div>
                        <a href={authUrl}>Zaloguj za pomocą steam</a>
                    </div>
                ) : (
                    <>
                        <div className={styles.avatarWrapper} onClick={() => navigate('/panel/my-account')}>
                            <div className={styles.avatar}>
                                <img alt={user.displayName} src={`${STEAM_AVATAR_URL}${user.avatarHash}.jpg`} />
                            </div>
                            <span>MOJE KONTO</span>
                        </div>
                        <div className={styles.logoutIcon}>
                            <LogoutIcon />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
export default NavBar;
