import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const BackDrop = ({ loading }: { loading: boolean }) => (
    <Backdrop
        open={loading}
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
        <CircularProgress color="inherit" />
    </Backdrop>
);

export default BackDrop;
