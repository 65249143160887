/* eslint-disable complexity */
import {
    IChest,
    IDrawedItem,
} from '../../../types/chest';
import { setBalance } from '../../../ducks/userSlice';
import { useAppDispatch } from '../../../hooks/useRedux';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Coin from '../Coin';
import DrawItem from '../DrawItem/DrawItem';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import Loader from '../Loader/Loader';
import React, {
    memo,
    useCallback,
    useEffect,
    useState,
} from 'react';
import axios from 'axios';
import classNames from 'classnames';
import styles from './DrawItems.module.scss';

interface IDrawItems {
    chestDetails: IChest;
}

const ITEM_SIZE = 200;

// eslint-disable-next-line complexity
const DrawItems = ({ chestDetails }: IDrawItems) => {
    const [isQuickDraw, setIsQuickDraw] = useState(false);
    const [items, setItems] = useState<IDrawedItem[][]>([[]]);
    const [isDrawing, setIsDrawing] = useState(false);
    const [isSelling, setIsSelling] = useState(false);
    const [openTimes, setOpenTimes] = useState(1);
    const [prizes, setPrizes] = useState<IDrawedItem[]>([]);
    const [prizesAvailable, setPrizesAvailable] = useState<boolean>(false);
    const [sellButtonBlocked, setSellButtonBlocked] = useState<boolean>(false);
    const [selledItems, setSelledItems] = useState<number[]>([]);
    const [awaitingForDrawing, setAwaitingForDrawing] = useState(false);
    const dispatch = useAppDispatch();

    const OPEN_TIME = isQuickDraw ? 1000 : 8000;

    useEffect(
        () => {
            const fakeItemsAll = [];

            for (let i = 0; i < openTimes; i++) {
                const length = chestDetails.items.length;

                const fakeItems = [];
                for (let counter = 0; counter < 11; counter++) {
                    const fakeIndex = Math.floor(Math.random() * length);
                    fakeItems.push(chestDetails.items[fakeIndex]);
                }
                fakeItemsAll.push(fakeItems);
            }

            setItems(fakeItemsAll as unknown as IDrawedItem[][]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [openTimes],
    );

    const handleReset = useCallback(
        () => {
            setIsDrawing(false);
            setSelledItems([]);
            setPrizesAvailable(false);
            setPrizes([]);
            setSellButtonBlocked(false);
            setIsSelling(false);
        },
        [],
    );

    const handleDraw = useCallback(
        () => {
            handleReset();
            setAwaitingForDrawing(true);
            axios.post(`/api/draw-items/${chestDetails.id}`, { times: openTimes })
                .then(({ data }) => {
                    setIsDrawing(true);
                    dispatch(setBalance(data.balance));

                    const rolledItemsResponse = data.rolls.map(({ fakeItems, prize }: { fakeItems: IDrawedItem[]; prize: IDrawedItem }) => {

                        const rolledItems = [...fakeItems];
                        rolledItems[100 - 6] = { ...prize };
                        return rolledItems;
                    });
                    const rolledPrizes = data.rolls.map(({ prize }: { prize: IDrawedItem }) => prize);

                    setItems(rolledItemsResponse);
                    setPrizes(rolledPrizes);
                    setTimeout(
                        () => {
                            setAwaitingForDrawing(false);
                        },
                        100,
                    );
                    setTimeout(
                        () => {
                            setPrizesAvailable(true);
                            setIsDrawing(false);
                        },
                        OPEN_TIME + (openTimes * 200),
                    );
                })
                .catch(() => {
                    setAwaitingForDrawing(false);
                });
        },
        [chestDetails.id, dispatch, openTimes, handleReset, OPEN_TIME],
    );

    const handleSellAll = useCallback(
        () => {
            const sellIds: string[] = [];
            setIsSelling(true);
            prizes.map((prize, index) => {
                if (!selledItems.includes(index))
                    sellIds.push(prize._id);
            });
            axios.post('/api/sell-items', { items: [...sellIds] }).then((res) => {
                dispatch(setBalance(res.data.balance));
                setSellButtonBlocked(true);
                setIsSelling(false);
            });
        },
        [dispatch, prizes, selledItems],
    );

    const changeRollersCount = useCallback(
        (openTimesCounter: number) => {
            setOpenTimes(openTimesCounter);
        },
        [],
    );

    const ButtonWrapper = memo(
        ({ id }: { id: number }) => (
            <Button
                onClick={() => changeRollersCount(id)}
                variant={openTimes === id ? 'contained' : 'outlined'}
            >
                {id}
            </Button>
        ),

    );

    const handleSellItem: (id: number) => void = useCallback(
        (id) => {
            setIsSelling(true);
            axios.post('/api/sell-items', { items: [prizes[id]._id] }).then((res) => {
                setIsSelling(false);
                dispatch(setBalance(res.data.balance));
                setSelledItems(prevVal => {
                    const newValues = [...prevVal, id];

                    if (newValues.length === openTimes) {
                        setSellButtonBlocked(true);
                    }

                    return newValues;
                });
            });
        },
        [dispatch, prizes, openTimes],
    );

    return (
        <div className={styles.wrapper}>
            <div>
                <div onClick={() => setIsQuickDraw(prev => !prev)}>
                    {!isQuickDraw
                        ? <FlashOnIcon />
                        : <FlashOffIcon />
                    }
                </div>
            </div>
            <div className={styles.carousellWrapper}>
                {items[0].length > 0 && (
                    <div
                        className={classNames(openTimes > 1 ? styles.multipleDrawerWrapper : styles.rollerWrapper)}
                        style={{ width: openTimes > 1 ? '100%' : `${10 * ITEM_SIZE}px` }}
                    >
                        {items.map((rolledItems, index) => (
                            <DrawItem
                                chestPrice={chestDetails.price}
                                isDrawing={isDrawing}
                                isVertical={openTimes > 1}
                                isWaitingForDraw={false}
                                isWaitingForSell={false}
                                itemSize={ITEM_SIZE}
                                items={rolledItems}
                                key={index}
                                openTimes={openTimes}
                                openingTime={OPEN_TIME}
                                prize={prizes[index]}
                                rollerIndex={index}
                                sellButtonBlocked={selledItems.includes(index) || sellButtonBlocked}
                                sellItem={handleSellItem}
                                showPrize={prizesAvailable}
                            />
                        ))}
                    </div>
                )}
            </div>
            {
                !prizesAvailable
                    ? (
                        <div className={styles.buttonsWrapper}>
                            {(isDrawing || awaitingForDrawing) && (
                                <div className={styles.blocker} />
                            )}
                            <ButtonGroup
                                color='warning'
                                disabled={(isDrawing || awaitingForDrawing)}
                                sx={{
                                    cursor: isDrawing ? 'wait' : 'pointer',
                                }}
                                variant="outlined"
                            >
                                <ButtonWrapper id={1} />
                                <ButtonWrapper id={2} />
                                <ButtonWrapper id={3} />
                                <ButtonWrapper id={4} />
                                <ButtonWrapper id={5} />
                            </ButtonGroup>
                            <Button
                                color='success'
                                onClick={() => !isDrawing && !isSelling && handleDraw()}
                                sx={{
                                    cursor: isDrawing ? 'wait' : 'pointer',
                                    fontFamily: 'stratumBold',
                                    height: '40px',
                                    width: '200px',
                                }}
                                variant="contained"
                            >
                                {
                                    (isDrawing || awaitingForDrawing || isSelling)
                                        ? <Loader isSmall />
                                        : <div>OTWÓRZ ZA {chestDetails.price * openTimes}</div>
                                }

                            </Button>
                        </div>
                    )
                    : (
                        <div className={styles.buttonsWrapper}>
                            <Button
                                color='primary'
                                onClick={handleReset}
                                sx={{
                                    cursor: isDrawing ? 'wait' : 'pointer',
                                    fontFamily: 'stratumBold',
                                    height: '40px',
                                }}
                                variant="contained"
                            >
                                {'<'}

                            </Button>
                            <Button
                                color='error'
                                disabled={isSelling || sellButtonBlocked}
                                onClick={handleSellAll}
                                sx={{
                                    cursor: isDrawing ? 'wait' : 'pointer',
                                    display: 'flex',
                                    fontFamily: 'stratumBold',
                                    gap: '5px',
                                    height: '40px',
                                    justifyContent: 'center',
                                    width: '200px',
                                }}
                                variant="contained"
                            >
                                <div>Sprzedaj za {prizes.reduce(
                                    (accumulator, { price }, index) =>
                                        accumulator + (selledItems.includes(index) ? 0 : price),
                                    0,
                                )}</div> <Coin />

                            </Button>

                            <Button
                                color='success'
                                onClick={() => !isDrawing && !isSelling && handleDraw()}
                                sx={{
                                    cursor: isDrawing ? 'wait' : 'pointer',
                                    fontFamily: 'stratumBold',
                                    height: '40px',
                                    width: '200px',
                                }}
                                variant="contained"
                            >
                                {
                                    (isDrawing || awaitingForDrawing || isSelling)
                                        ? <Loader isSmall />
                                        : <div>OTWÓRZ ZA {chestDetails.price * openTimes}</div>
                                }
                            </Button>
                        </div>
                    )
            }
        </div>
    );
};

export default DrawItems;
