import { IUser } from '../types/User';
import { RootState } from './store';
import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import axios from 'axios';

// ACTIONS
export const getUser = createAsyncThunk('getUser', async () => {
    try {
        return await axios.get('/api/get-user').then(({ data, status }) => {
            if (status === 200) {
                return data;
            }
        });
    } catch (error) {
        console.log('Something went wrong', error);
    }
});

// INITIAL STATE
export const initialState = {
    data: {} as IUser,
    isPending: false,
};

// SLICE
export const userSlice = createSlice({
    extraReducers: (builder) => {
        builder
            .addCase(getUser.pending, (state) => {
                state.isPending = true;
            })
            .addCase(getUser.fulfilled, (state, action) => {
                state.isPending = false;
                state.data = action.payload;
            })
            .addCase(getUser.rejected, (state) => {
                state.isPending = false;
            });
    },
    initialState,
    name: 'user',
    reducers: {
        setBalance: (state, action) => {
            state.data.balance = action.payload;
        },
    },
});

// ACTIONS
export const { setBalance } = userSlice.actions;

// SELECTORS
export const selectIsUserDetailsPending = (state: RootState) => state.user.isPending;
export const selectLogedUser = (state: RootState) => state.user.data;
export const selectUserBalance = (state: RootState): number => state.user.data.balance;
