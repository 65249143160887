/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert } from '../../shared/components/Alert/Alert';
import { IChest } from '../../types/chest';
import { TextField } from '@mui/material';
import { caseImg } from '../../mocks/mocks';
import { useForm } from 'react-hook-form';
import Avatar from '@mui/material/Avatar';
import BackDrop from '../../components/BackDrop';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import Snackbar from '@mui/material/Snackbar';
import WikiTable from '../WikiTable';
import axios from 'axios';

interface IProps {
    chestId?: string | null;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ChestModal = ({ chestId, open, setOpen }: IProps) => {
    // Form
    const {
        formState: {
            errors,
        },
        handleSubmit,
        register,
        setValue,
    } = useForm();

    // Templates
    const [base64Image, setBase64Image] = useState('');
    const [suggestedPrice, setSuggestedPrice] = useState<number | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [items, setItems] = useState<[]>([]);
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [weaponTypeValue, setWeaponTypeValue] = useState('');
    const [openAlert, setOpenAlert] = React.useState(false);

    const [chestContent, setChestContent] = useState<IChest[]>([]);

    useEffect(
        () => {
            if (chestId) {
                axios.get(`/api/get-chest/${chestId}`).then(({ data }) => {
                    setBase64Image(data.icon);
                    setValue('price', data.price);
                    setValue('name', data.name);
                    setChestContent(data.items.map((item: any) => ({
                        className: item.className,
                        id: item.id,
                        itemIcon: item.icon,
                        plName: item.name,
                        weaponType: item.weaponType,
                    })));
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const fetchItems = useCallback(() => {
        setLoading(true);
        axios
            .get('/api/get-templates', { params: { limit, page, search: searchValue, weaponType: weaponTypeValue } })
            .then((res) => {
                setItems(res.data.items);
                setLimit(res.data.limit);
                setPage(res.data.page);
                setTotalItems(res.data.totalItems);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    }, [limit, page, searchValue, weaponTypeValue]);

    useEffect(() => {
        fetchItems();
    }, [fetchItems, limit, page]);

    const onSubmit = (data: unknown) => {
        setLoading(true);
        const chestData = data as { name: string; price: number };
        const chestContentIds = chestContent.map(chest => chest.id);
        const payload = {
            icon: base64Image,
            items: chestContentIds,
            name: chestData.name,
            price: chestData.price,
        };

        axios
            .post('/api/save-chest', payload)
            .then(() => {
                setLoading(false);
                setOpen(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    };

    const onSave = (data: unknown) => {
        setLoading(true);
        const chestData = data as { name: string; price: number };
        const chestContentIds = chestContent.map(chest => chest.id);
        const payload = {
            icon: base64Image,
            items: chestContentIds,
            name: chestData.name,
            price: chestData.price,
        };

        axios
            .post(`/api/update-chest/${chestId}`, payload)
            .then(() => {
                setLoading(false);
                setOpen(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnRowClick = (e: IChest) => {
        if (chestContent.find(c => c.id === e.id)) {
            setOpenAlert(true);
        } else {
            setChestContent(prev => [...prev, e]);
        }
    };

    const deleteItem = (id: string) => setChestContent(prev => prev.filter(prevItem => prevItem.id !== id));

    const handleCloseAlert = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const convertBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileRead = async (event: any) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setBase64Image(base64 as string);
    };

    const checkPrice = () => {
        axios.post('/api/draw-price', {
            items: chestContent.map(chest => chest.id),
        }).then(({ data }) => {
            setValue('price', Math.floor(data.suggestedMinimalPrice));
            setSuggestedPrice(data.suggestedMinimalPrice);
        });
    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                autoHideDuration={2000}
                onClose={handleCloseAlert}
                open={openAlert}
            >
                <Alert onClose={handleCloseAlert} severity="error" sx={{ width: '100%' }}>
                    Ten przedmiot został już dodany do obecnej konfiguracji.
                </Alert>
            </Snackbar>
            <Dialog maxWidth="xl" onClose={handleClose} open={open}>
                <BackDrop loading={loading} />
                <DialogTitle>Dodaj skrzynię</DialogTitle>
                <DialogContent>
                    <div style={{ fontFamily: 'Roboto, Helvetica', marginBottom: '20px' }}>
                        Dodaj nową skrzynię uzupełniając poniższe dane.
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display: 'flex', marginBottom: '20px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', minWidth: '300px' }}>
                                    <TextField
                                        {...register('name', { required: true })}
                                        color="primary"
                                        focused
                                        label="Nazwa *"
                                        placeholder="Nazwa *"
                                        sx={{
                                            marginBottom: `${errors.name ? '10px' : '20px'}`,
                                        }}
                                    />
                                    {errors.name &&
                                    <span style={{ color: '#ee1e1e', fontFamily: 'Roboto, Helvetica', marginBottom: '20px' }}>
                                        To pole jest wymagane
                                    </span>
                                    }
                                    <TextField
                                        {...register('price', { required: true })}
                                        InputProps={{ inputProps: { min: 0 } }}
                                        color="primary"
                                        focused
                                        label="Cena *"
                                        placeholder="0"
                                        type="number"
                                    />
                                    <Button onClick={checkPrice}>Wyceń</Button>
                                    {suggestedPrice}
                                    {errors.price &&
                                        <span style={{ color: '#ee1e1e', fontFamily: 'Roboto, Helvetica', marginBottom: '20px' }}>
                                            To pole jest wymagane
                                        </span>
                                    }
                                </div>
                                <div style={{ border: '1px #121212 solid', margin: '10px 50px' }} />
                                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Avatar
                                        alt="chest-icon"
                                        src={base64Image || caseImg}
                                        sx={{ height: 240, width: 240 }}
                                        variant="square"
                                    />
                                    <TextField
                                        onChange={e => handleFileRead(e)}
                                        type="file"
                                    />
                                </div>
                            </div>
                            <div style={{ fontFamily: 'Roboto, Helvetica', marginBottom: '20px' }}>
                                <div style={{ marginRight: '10px' }}>Zawarość skrzyni</div>
                                {chestContent.length ? (
                                    <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px', maxWidth: '700px' }}>
                                        {chestContent.map((content: any) => (
                                            <div
                                                key={content.id}
                                                style={{
                                                    alignItems: 'center',
                                                    border: '1px solid #121212',
                                                    borderRadius: '10px',
                                                    display: 'flex',
                                                    margin: '0 5px 5px 0',
                                                    padding: '5px',
                                                }}>
                                                <Avatar
                                                    alt="Item icon"
                                                    src={content.itemIcon}
                                                    sx={{ height: 32, marginRight: '5px', width: 32 }}
                                                    variant="square"
                                                />
                                                <div>{content.plName}</div>
                                                <DeleteIcon
                                                    onClick={() => deleteItem(content.id)}
                                                    sx={{
                                                        cursor: 'pointer',
                                                        marginLeft: '5px',
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : (
                                    <div style={{ marginTop: '10px' }}>-</div>
                                )}
                            </div>
                            <div style={{ minWidth: '800px' }}>
                                <WikiTable
                                    clickable
                                    items={items}
                                    limit={limit}
                                    loading={loading}
                                    onRowClick={handleOnRowClick}
                                    page={page}
                                    setLimit={setLimit}
                                    setPage={setPage}
                                    setSearchValue={setSearchValue}
                                    setWeaponTypeValue={setWeaponTypeValue}
                                    totalItems={totalItems}
                                />
                            </div>
                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Anuluj</Button>
                    {
                        !chestId
                            ? <Button onClick={handleSubmit((e) => onSubmit(e))} variant="contained">Dodaj</Button>
                            : <Button onClick={handleSubmit((e) => onSave(e))} variant="contained">Zapisz</Button>
                    }
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ChestModal;
