import { DEFAULT_KNIFE_IMAGE } from '../../common';
import { ITEM_RARITY } from '../../../types/enums';
import Logo from '../Logo/Logo';
import React from 'react';
import classNames from 'classnames';
import styles from './ItemImage.module.scss';

interface IItemImage {
    icon: string;
    isKnife: boolean;
    isSmall?: boolean;
    noAnimation?: boolean;
    rarity: ITEM_RARITY;
}

const ItemImage = ({
    rarity,
    icon = DEFAULT_KNIFE_IMAGE,
    // isSmall,
    noAnimation,
    isKnife,
}: IItemImage) => {
    const classes = (itemRarity: ITEM_RARITY) => ({
        [ITEM_RARITY.CLASSIFIED]: styles.classGold,
        [ITEM_RARITY.CONSUMER_GRADE]: styles.classWhite,
        [ITEM_RARITY.CONTRABAND]: styles.classPurple,
        [ITEM_RARITY.COVERT]: styles.classRed,
        [ITEM_RARITY.INDUSTRIAL_GRADE]: styles.classLightBlue,
        [ITEM_RARITY.MIL_SPEC]: styles.classBlue,
        [ITEM_RARITY.RESTRICTED]: styles.classPink,
    })[itemRarity];

    const logoClasses = (itemRarity: ITEM_RARITY) => ({
        [ITEM_RARITY.CLASSIFIED]: styles.logoClassGold,
        [ITEM_RARITY.CONSUMER_GRADE]: styles.logoClassWhite,
        [ITEM_RARITY.CONTRABAND]: styles.logoClassPurple,
        [ITEM_RARITY.COVERT]: styles.logoClassRed,
        [ITEM_RARITY.INDUSTRIAL_GRADE]: styles.logoClassLightBlue,
        [ITEM_RARITY.MIL_SPEC]: styles.logoClassBlue,
        [ITEM_RARITY.RESTRICTED]: styles.logoClassPink,
    })[itemRarity];

    return (
        <div className={classNames(
            styles.wrapper,
            !noAnimation && styles.animated,
        )}>
            <img
                alt="img"
                className={styles.itemImage}
                draggable={false}
                src={icon}
            />
            <div className={styles.backgroundBlur} />
            <div className={classNames(
                styles.logo,
                isKnife
                    ? styles.logoClassGold
                    : logoClasses(rarity),
            )}>
                <Logo />
            </div>
            <div className={styles.backdrop}>
                <div className={classNames(
                    styles.classColor,
                    isKnife
                        ? styles.classGold
                        : classes(rarity),
                )} />
            </div>
        </div>
    );
};

export default ItemImage;
