/* eslint-disable max-len */
/* eslint-disable no-console */
import { Box } from '@mui/material';
import { IWeaponTemplate } from '../../../../types/WeaponTemplate';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import {
    ITEM_RARITY,
    ITEM_WEAR,
} from '../../../../types/enums';
import DialogTitle from '@mui/material/DialogTitle';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import styles from './TemplateModal.module.scss';
import weaponBasicData from '../../../../shared/weaponBasicData';

interface TemplateResponse extends IWeaponTemplate {
    steamLink: {
        bs: string;
        fn: string;
        ft: string;
        mw: string;
        ww: string;
    };
}

interface ITemplateModal {
    handleModalState: (isOpen: boolean) => void;
    isOpen: boolean;
    templateId: string;
}

export const TemplateModal = ({
    handleModalState,
    isOpen,
    templateId,
}: ITemplateModal) => {
    const [templateData, setTemplateData] = useState({} as TemplateResponse);

    const [prices, setPrices] = useState({} as TemplateResponse['price']);
    const [name, setName] = useState({
        enName: '',
        plName: '',
    });
    const [rarity, setRarity] = useState(undefined as unknown as ITEM_RARITY);
    const [itemIcons, setItemIcon] = useState({} as TemplateResponse['itemIcon']);

    useEffect(
        () => {
            if (templateId !== '') {
                axios.get(`/api/get-template/${templateId}`).then(template => {
                    setTemplateData(template.data);
                    setPrices({ ...template.data.price, _id: undefined });
                    setItemIcon(template.data.itemIcon);
                    setName({
                        enName: template.data.enName,
                        plName: template.data.plName,
                    });
                    setRarity(
                        template.data.rarity === 'stock'
                            ? ITEM_RARITY.CONSUMER_GRADE
                            : template.data.rarity,
                    );
                });
            } else {
                setTemplateData({} as TemplateResponse);
            }
        },
        [templateId],
    );

    const handlePriceChange = useCallback(
        (wear: ITEM_WEAR, priceVal: string) => {
            const price = parseInt(priceVal);
            setPrices(prevVal => ({
                ...prevVal,
                [wear]: price,
            }));
        },
        [],
    );

    const handleNameChange = useCallback(
        (isPl: boolean, skinName: string) => {
            if (isPl) {
                setName(prevName => ({
                    ...prevName,
                    plName: skinName,
                }));
            } else {
                setName(prevName => ({
                    ...prevName,
                    enName: skinName,
                }));
            }
        },
        [],
    );

    const handleClose = () => {
        const data = {
            ...templateData,
            _id: undefined,
            enName: name.enName,
            plName: name.plName,
            prices,
            rarity,
        } as IWeaponTemplate;

        axios.patch(`/api/update-template/${templateData._id}`, data).then(() => {
            handleModalState(false);
        });
    };

    const calcPrices = () => {
        setPrices(prevVal => ({
            battleScarred: Math.floor(prevVal.factoryNew * 0.4),
            factoryNew: prevVal.factoryNew,
            fieldTested: Math.floor(prevVal.factoryNew * 0.7),
            minimalWear: Math.floor(prevVal.factoryNew * 0.81),
            wellWorn: Math.floor(prevVal.factoryNew * 0.5),
        }));
    };

    const getRarityClass = (itemRarity: ITEM_RARITY) => ({
        [ITEM_RARITY.CONSUMER_GRADE]: styles.classWhite,
        [ITEM_RARITY.INDUSTRIAL_GRADE]: styles.classLightBlue,
        [ITEM_RARITY.MIL_SPEC]: styles.classBlue,
        [ITEM_RARITY.CONTRABAND]: styles.classPurple,
        [ITEM_RARITY.RESTRICTED]: styles.classPink,
        [ITEM_RARITY.COVERT]: styles.classRed,
        [ITEM_RARITY.CLASSIFIED]: styles.classGold,
    })[itemRarity];

    return (
        <div>
            <Dialog
                // fullWidth
                maxWidth="md"
                // onClose={handleClose}
                open={!!templateData._id && isOpen}
            >
                {templateData._id
                    ? (<>
                        <DialogTitle>
                            {weaponBasicData[templateData.weaponType]?.name.en} {templateData.enName}
                        </DialogTitle>
                        <DialogContent>
                            <Box sx={{
                                display: 'flex',
                                gap: '20px',
                                width: '100%',
                            }}>
                                <Box>
                                    <TextField
                                        focused
                                        id="name"
                                        label="PL nazwa"
                                        onChange={(data) => { handleNameChange(true, data.target.value); }}
                                        value={name.plName}
                                        variant="standard"
                                    />
                                </Box>
                                <Box>
                                    <select
                                        id="cars"
                                        name="cars"
                                        onChange={(data) => { setRarity(data.target.value as ITEM_RARITY); }}
                                        value={rarity}
                                    >
                                        {Object.values(ITEM_RARITY).map((rarityClass) => (
                                            <option
                                                className={getRarityClass(rarityClass)}
                                                key={rarityClass}
                                                value={rarityClass}
                                            >
                                                {rarityClass}
                                            </option>
                                        ))}
                                    </select>
                                </Box>
                                <Box>
                                    <TextField
                                        focused
                                        id="name"
                                        label="PL nazwa"
                                        onChange={(data) => { handleNameChange(false, data.target.value); }}
                                        value={name.enName}
                                        variant="standard"
                                    />
                                </Box>
                            </Box>
                            <Box sx={{
                                display: 'flex',
                                gap: '20px',
                                width: '100%',
                            }}>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '10px',
                                }}>
                                    <Box>
                                        <img alt="fn" src={itemIcons} />
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}>
                                    <TextField
                                        focused
                                        id="name"
                                        onChange={({ target }) => { handlePriceChange(ITEM_WEAR.FACTORY_NEW, target.value); }}
                                        type="number"
                                        value={prices.factoryNew}
                                        variant="standard"
                                    />
                                    <TextField
                                        focused
                                        id="name"
                                        onChange={({ target }) => { handlePriceChange(ITEM_WEAR.MINIMAL_WEAR, target.value); }}
                                        type="number"
                                        value={prices.minimalWear}
                                        variant="standard"
                                    />
                                    <TextField
                                        focused
                                        id="name"
                                        onChange={({ target }) => { handlePriceChange(ITEM_WEAR.FIELD_TESTED, target.value); }}
                                        type="number"
                                        value={prices.fieldTested}
                                        variant="standard"
                                    />
                                    <TextField
                                        focused
                                        id="name"
                                        onChange={({ target }) => { handlePriceChange(ITEM_WEAR.WELL_WORN, target.value); }}
                                        type="number"
                                        value={prices.wellWorn}
                                        variant="standard"
                                    />
                                    <TextField
                                        focused
                                        id="name"
                                        onChange={({ target }) => { handlePriceChange(ITEM_WEAR.BATTLE_SCARRED, target.value); }}
                                        type="number"
                                        value={prices.battleScarred}
                                        variant="standard"
                                    />
                                    <Button onClick={calcPrices}>Calc</Button>
                                </Box>
                            </Box>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => handleModalState(false)}>Cancel</Button>
                            <Button onClick={handleClose}>Subscribe</Button>
                        </DialogActions>
                    </>)
                    : (
                        <div>Loading</div>
                    )}
            </Dialog>
        </div>
    );
};

export default TemplateModal;
