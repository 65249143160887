import {
    IChestsGroup,
    IChestsGroupItem,
} from '../../../../../../types/ChestsGroup';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ChestItem from '../ChestItem/ChestItem';
import ChestsModal from '../ChestsModal/ChestsModal';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import classNames from 'classnames';
import styles from './CategoryItem.module.scss';

interface ICategoryItem {
    category: IChestsGroup;
    categoryIndex: number;
    changeChestsOrder: (chestIndex: number, items: IChestsGroupItem[]) => void;
    moveCategory: (index: number, moveUp?: boolean) => void;
    removeCategory: (index: number) => void;
}

const CategoryItem = ({
    category,
    categoryIndex,
    changeChestsOrder,
    moveCategory,
    removeCategory,
}: ICategoryItem) => {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const [items, setItems] = useState([] as IChestsGroupItem[]);
    const [isModalOpened, setIsModalOpened] = useState(false);

    const moveItem = (index: number, moveUp?: boolean) => {
        setItems(prevState => {
            const copiedItems = [...prevState];
            if (moveUp) {
                if (index === 0) {
                    return copiedItems;
                }
                copiedItems.splice(index - 1, 0, copiedItems.splice(index, 1)[0]);
            } else {
                if (index === copiedItems.length - 1) {
                    return copiedItems;
                }
                copiedItems.splice(index + 1, 0, copiedItems.splice(index, 1)[0]);
            }
            return copiedItems;
        });
    };

    const removeItem = (index: number) => {
        setItems(prevState => {
            const copiedItems = [...prevState];

            copiedItems.splice(index, 1);

            return copiedItems;
        });
    };

    useEffect(
        () => {
            if (items.length) {
                changeChestsOrder(categoryIndex, items);
            }
        },
        [category, categoryIndex, changeChestsOrder, items],
    );

    useEffect(
        () => {
            setItems(category.items);
        },
        [category],
    );

    const handleOpenModal = useCallback(
        () => {
            setIsModalOpened(true);
            setIsCollapsed(false);
        },
        [],
    );

    const handleCloseModal = useCallback(
        () => {
            setIsModalOpened(false);
        },
        [],
    );

    const addChestToCategory = (chest: IChestsGroupItem) => {
        const foundItem = items.filter(item => item.id === chest.id);
        if (foundItem.length < 1) {
            setItems([
                {
                    id: chest.id,
                    name: chest.name,
                },
                ...items,
            ]);

        }
    };

    return (
        <div className={styles.wrapper}>
            <ChestsModal
                addChestToCategory={addChestToCategory}
                closeModal={handleCloseModal}
                isOpen={isModalOpened}
            />
            <div className={styles.groupHeader}>
                <div
                    className={styles.collapseButton}
                    onClick={() => setIsCollapsed(prev => !prev)}
                >
                    {!isCollapsed
                        ? <ExpandLessIcon />
                        : <ExpandMoreIcon />
                    }
                </div>
                <div>{category.name}</div>
                <div className={styles.actionsIcons}>
                    <div onClick={() => { moveCategory(categoryIndex, true); }}><ArrowUpwardIcon /></div>
                    <div onClick={() => { moveCategory(categoryIndex, false); }}><ArrowDownwardIcon /></div>
                    <div onClick={() => handleOpenModal()}><ControlPointIcon /></div>
                    <div onClick={() => removeCategory(categoryIndex)} ><HighlightOffIcon /></div>
                </div>
            </div>
            <div className={classNames(styles.group, isCollapsed ? styles.collapsed : styles.expanded)}>
                {items.map((item, index) => (
                    <div
                        className={styles.chestItem}
                        key={index}
                    >
                        <ChestItem
                            index={index}
                            item={item}
                            moveItem={moveItem}
                            removeItem={removeItem}
                        />
                    </div>
                ))}
            </div>

        </div>
    );
};

export default CategoryItem;
