import React, {
    // useCallback,
    useState,
} from 'react';
import styles from './Pie.module.scss';

const Pie = () => {
    const [percent, setPercent] = useState(5);
    const [deg, setDeg] = useState(0);

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.pie}
                onClick={() => { setPercent(Math.random() * 100); }}
                style={{ background: `conic-gradient( #ff9d00b5 0%, #ff9d00b5 0.00% ${percent}%, #0000007d ${percent}%)` }}
            />
            <div
                className={styles.innerCircle}
                onClick={() => { setDeg(360 * 4 + (Math.random() * 360)); }}
            >
                {Math.floor(percent)}%
            </div>
            <div
                className={styles.outerCircle}
                style={{ transform: `rotateZ(${deg}deg)` }}
            >
                <div className={styles.notch}></div>
            </div>
        </div>
    );
};

export default Pie;
