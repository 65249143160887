/* eslint-disable no-constant-condition */
import { IDrawedItem } from '../../../types/chest';
import {
    ITEM_RARITY,
    ITEM_WEAR,
    ITEM_WEAR_SHORT,
    KNIFES,
} from '../../../types/enums';
import Coin from '../Coin';
import ItemImage from '../ItemImage';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import classNames from 'classnames';
// import mock from './mock.json';
import styles from './DrawItem.module.scss';
import weaponBasicData from '../../weaponBasicData';

interface IDrawItem {
    chestPrice: number;
    isDrawing: boolean;
    isVertical?: boolean;
    isWaitingForDraw: boolean;
    isWaitingForSell: boolean;
    itemSize: number;
    items: IDrawedItem[];
    openTimes: number;
    openingTime: number;
    prize: IDrawedItem;
    rollerIndex: number;
    sellButtonBlocked: boolean;
    sellItem: (id: number) => void;
    showPrize: boolean;
}

// eslint-disable-next-line complexity
const DrawItem = ({
    chestPrice,
    sellItem,
    isDrawing,
    isVertical,
    itemSize,
    items,
    openTimes,
    openingTime,
    prize = {} as IDrawedItem,
    rollerIndex,
    sellButtonBlocked,
    showPrize,
}: IDrawItem) => {
    const [actualPrize, setActualPrize] = useState(prize);
    const [isRolling, setIsRolling] = useState(false);
    const [drawedItems, setDrawedItems] = useState(items);
    const [notchPosition, setNotchPosition] = useState(itemSize / 2);
    const [sellButtonFetching, setSellButtonFetching] = useState(false);

    const rollingTime = useMemo(
        () => openingTime + (rollerIndex * 200),
        [openingTime, rollerIndex],
    );

    const minPosition = !isVertical
        ? itemSize
        : (itemSize * 5) - 25;
    const maxPosition = !isVertical
        ? (itemSize * 90)
        : (itemSize * 94) - 25;

    useEffect(
        () => {
            setSellButtonFetching(false);
        },
        [sellButtonBlocked],
    );

    useEffect(
        () => {
            if (isDrawing) {
                setIsRolling(true);
                setNotchPosition(Math.floor(Math.random() * (itemSize - 20)) + 10);
                setDrawedItems(items);

                setTimeout(
                    () => {
                        setIsRolling(false);
                    },
                    rollingTime + 200,
                );

                setTimeout(
                    () => {
                        setDrawedItems(prevItems => {
                            const copiedItems = [...prevItems];
                            const lastItems = copiedItems.splice(89, 11);
                            copiedItems.splice(0, 11);

                            const returned = [...lastItems, ...copiedItems, ...lastItems];

                            return returned;
                        });
                        setActualPrize(prize);
                    },
                    1000,
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [isDrawing],
    );

    const itemsWrapperStyles = useMemo(
        () => {
            return !isVertical
                ? ({
                    left: !isRolling
                        ? `-${minPosition - notchPosition}px`
                        : `-${maxPosition - notchPosition}px`,
                    top: '25px',
                    transition: `left ${isRolling ? (rollingTime / 1000) : 0}s ease-out`,
                })
                : ({
                    left: '0',
                    top: !isRolling
                        ? `-${minPosition}px`
                        : `-${maxPosition}px`,
                    transition: `top ${isRolling ? (rollingTime / 1000) : 0}s ease-out`,
                    width: '100%',
                });
        },
        [isRolling, isVertical, maxPosition, minPosition, notchPosition, rollingTime],
    );

    const itemWrapperStyles = useMemo(
        () => {
            if (!isVertical) {
                return ({ borderRight: '1px solid black' });
            } else {
                return ({ borderBottom: '1px solid black' });
            }
        },
        [isVertical],
    );

    const handleSellItem = useCallback(
        (id: number) => {
            sellItem(id);
            setSellButtonFetching(true);
        },
        [sellItem],
    );

    return (
        <div
            className={styles.wrapper}
            style={{ height: `${itemSize + 50 - 1}px` }}
        >
            {openTimes === 1 && (
                <div
                    className={styles.notch}
                    style={{
                        height: `${itemSize + 50}px`,
                        top: '0',
                    }}
                />
            )

            }
            <div
                className={styles.itemsWrapper}
                style={{
                    ...itemsWrapperStyles,
                    alignItems: 'center',
                    flexDirection: !isVertical
                        ? 'row'
                        : 'column',
                }}
            >
                {drawedItems.map((item, index) => (
                    <div
                        className={styles.itemWrapper}
                        key={index}
                        style={{
                            height: `${itemSize}px`,
                            width: `${itemSize}px`,
                            ...itemWrapperStyles,
                        }}
                    >
                        <ItemImage
                            icon={item.icon}
                            isKnife={KNIFES.includes(item.weaponType)}
                            noAnimation
                            rarity={item.rarity as ITEM_RARITY}
                        />
                    </div>
                ))}
            </div>
            <div className={classNames(styles.prizeWrapper, showPrize ? styles.visible : styles.hidden)}>
                <div className={classNames(styles.prize, sellButtonBlocked && styles.prizeSelled)}>
                    <ItemImage
                        icon={actualPrize.icon}
                        isKnife={KNIFES.includes(actualPrize.weaponType)}
                        rarity={actualPrize.rarity as ITEM_RARITY}
                    />
                    <div className={styles.prizeDescriptionWrapper}>
                        <div className={styles.prizeDescription}>
                            <div className={classNames(styles.type, actualPrize.statTrack && styles.statTrack)}>
                                {weaponBasicData[actualPrize.weaponType]?.name?.pl} {actualPrize.statTrack && '(StatTrak™)'}
                            </div>
                            <div className={styles.name}>{actualPrize.name}</div>
                            <div className={classNames(
                                styles.wear,
                                actualPrize.wear === ITEM_WEAR.FACTORY_NEW && styles.wearFN,
                            )}>
                                {ITEM_WEAR_SHORT[actualPrize.wear]} - {Math.floor(actualPrize.float * 10000) / 10000}
                            </div>
                        </div>
                        {!isVertical
                            ? <div
                                className={classNames(
                                    styles.price,
                                    actualPrize.price > chestPrice && styles.goodPrice,
                                    actualPrize.price > 2 * chestPrice && styles.grandPrice,
                                )}
                            >
                                {actualPrize.price}
                                <Coin />
                            </div>
                            : <div className={styles.priceButtonWrapper}>
                                <div
                                    className={classNames(
                                        styles.priceButton,
                                        actualPrize.price > chestPrice && styles.goodPrize,
                                        actualPrize.price > 2 * chestPrice && styles.grandPrize,
                                        sellButtonBlocked && styles.blocked,
                                        sellButtonFetching && styles.isFetching,
                                    )}
                                    onClick={() => (!sellButtonBlocked && !sellButtonFetching) && handleSellItem(rollerIndex)}
                                >
                                    +{actualPrize.price}<Coin />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default DrawItem;
