/* eslint-disable max-len */
export const gamesCards = [
    {
        bgColor: '#203f52',
        id: '1',
        image: 'https://steamcdn-a.akamaihd.net/apps/dota2/images/blog/play/dota_heroes.png',
        subtitle: 'Be a Legend!',
        title: 'Dota 2',
    },
    {
        bgColor: '#4d137f',
        id: '2',
        image: 'https://progameguides.com/wp-content/uploads/2019/10/fortnite-outfit-scratch.jpg',
        subtitle: 'Time to choose side!',
        title: 'Fortnite',
    },
    {
        bgColor: '#ff9900',
        id: '3',
        image: 'https://images5.alphacoders.com/690/thumb-1920-690653.png',
        subtitle: 'What are you waiting?',
        title: 'Overwatch',
    },
    {
        bgColor: '#34241e',
        id: '4',
        image: 'https://www.itp.net/public/styles/full_img_sml/public/images/2019/05/27/44485-pubg_base1.jpg?itok=EF911Xan',
        subtitle: 'Are you ready?',
        title: 'PUBG',
    },
];

export const cases = [
    {
        bgColor: '#203f52',
        id: '1',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'Be a Legend!',
        title: 'Title 1',
    },
    {
        bgColor: '#4d137f',
        id: '2',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'Time to choose side!',
        title: 'Title 2',
    },
    {
        bgColor: '#ff9900',
        id: '3',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'What are you waiting?',
        title: 'Title 3',
    },
    {
        bgColor: '#e95925',
        id: '4',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'Are you ready?',
        title: 'Title 4',
    },
    {
        bgColor: '#dc1e1e',
        id: '5',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'What are you waiting for?',
        title: 'Title 5',
    },
    {
        bgColor: '#34241e',
        id: '6',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/460fx460f',
        subtitle: 'It is time to choose!',
        title: 'Title 6',
    },
];

export const equipment = [
    {
        bgColor: '#4d137f',
        id: '1',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpou-6kejhnwMzFJTwW09m7hIWZmOXLP7LWnn9u5MRjjeyPo933jQDs_kZlMWmiIdKQdVM-ZVnQ8ge2xui5gMXqu5jMnCFr63Nx7WGdwUKmIT3TEw/360fx360f',
        subtitle: 'Are you ready?',
        title: 'M4A4',
    },
    {
        bgColor: '#4d137f',
        id: '2',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/ZmUL1L-DbKmXEj7Hfm_AxIVsFbzdM-hZw-eh-Lov187RhFMnjLF_SdWayhG9LY4Blw/360fx360f',
        subtitle: 'Are you ready?',
        title: 'AK47',
    },
    {
        bgColor: '#4d137f',
        id: '3',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/ZmUL1L-DbKmXEj7Hfm_AxIVsFbzdM-hZw-eh-Lov187RhFA8kbZ8TczE1BiVz4sFCQ8/360fx360f',
        subtitle: 'Are you ready?',
        title: 'DEAGLE | VISTA',
    },
    {
        bgColor: '#4d137f',
        id: '4',
        image:
      'https://community.akamai.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpopbuyLgNv1fX3eSR96NimlZS0mOX1PbzUqWZU7Mxkh9bN9J7yjRrk80JpYGihLdOXdwE8M1HQ-QPrkL3vgpDo6pTNzCE3uChw5HuJn0C1n1gSObVbszu7/360fx360f',
        subtitle: 'Are you ready?',
        title: 'Bloom Buffed',
    },
    {
        bgColor: '#4d137f',
        id: '5',
        image:
      'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXH5ApeO4YmlhxYQknCRvCo04DEVlxkKgpovbSsLQJf2PLacDBA5ciJl5W0nPbmMrbummRD7fp9g-7J4bP5iUazrl1rY2DzddPEdwFsYgnSqwPqyey6hZ_qvM6dm3pnunFx4n2LmxTj1x9PcKUx0uHhTF2B/360fx360f',
        subtitle: 'Are you ready?',
        title: 'Karambit (★)',
    },
];

export const caseImg = 'https://community.cloudflare.steamstatic.com/economy/image/-9a81dlWLwJ2UUGcVs_nsVtzdOEdtWwKGZZLQHTxDZ7I56KU0Zwwo4NUX4oFJZEHLbXU5A1PIYQNqhpOSV-fRPasw8rsUFJ5KBFZv668FFQwnfCcJmxDv9rhwIHZwqP3a-uGwz9Xv8F0j-qQrI3xiVLkrxVuZW-mJoWLMlhpWhFkc9M/220fx220f';
