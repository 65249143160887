import { ITEM_WEAR } from '../../types/enums';

export default (float: number) => {
    if (float < 0.07) {
        return ITEM_WEAR.FACTORY_NEW;
    } else if (float < 0.15) {
        return ITEM_WEAR.MINIMAL_WEAR;
    } else if (float < 0.38) {
        return ITEM_WEAR.FIELD_TESTED;
    } else if (float < 0.45) {
        return ITEM_WEAR.WELL_WORN;
    } else {
        return ITEM_WEAR.BATTLE_SCARRED;
    }
};
