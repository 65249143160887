import { WEAPON_TYPE } from '../types/enums';

/* eslint-disable max-len */
export default {
    [`${WEAPON_TYPE.AK47}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_ak47.png',
    [`${WEAPON_TYPE.AUG}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_aug.png',
    [`${WEAPON_TYPE.AWP}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_awp.png',
    [`${WEAPON_TYPE.BAYONET}`]: '',
    [`${WEAPON_TYPE.BIZON}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_bizon.png',
    [`${WEAPON_TYPE.CZ75A}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_cz75a.png',
    [`${WEAPON_TYPE.DEAGLE}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_deagle.png',
    [`${WEAPON_TYPE.ELITE}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_elite.png',
    [`${WEAPON_TYPE.FAMAS}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_famas.png',
    [`${WEAPON_TYPE.FIVESEVEN}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_fiveseven.png',
    [`${WEAPON_TYPE.G3SG1}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_g3sg1.png',
    [`${WEAPON_TYPE.GALILAR}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_galilar.png',
    [`${WEAPON_TYPE.GLOCK}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_glock.png',
    [`${WEAPON_TYPE.GLOVES_BLOODHOUND}`]: '',
    [`${WEAPON_TYPE.GLOVES_BROKEN_FANG}`]: '',
    [`${WEAPON_TYPE.GLOVES_DRIVER}`]: '',
    [`${WEAPON_TYPE.GLOVES_HAND_WRAPS}`]: '',
    [`${WEAPON_TYPE.GLOVES_HYDRA}`]: '',
    [`${WEAPON_TYPE.GLOVES_MOTO}`]: '',
    [`${WEAPON_TYPE.GLOVES_SPECIALIST}`]: '',
    [`${WEAPON_TYPE.GLOVES_SPORT}`]: '',
    [`${WEAPON_TYPE.HKP2000}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_hkp2000.png',
    [`${WEAPON_TYPE.KNIFE_BUTTERFLY}`]: '',
    [`${WEAPON_TYPE.KNIFE_CANIS}`]: '',
    [`${WEAPON_TYPE.KNIFE_CORD}`]: '',
    [`${WEAPON_TYPE.KNIFE_CSS}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_knife_css.png',
    [`${WEAPON_TYPE.KNIFE_FALCHION}`]: '',
    [`${WEAPON_TYPE.KNIFE_FLIP}`]: '',
    [`${WEAPON_TYPE.KNIFE_GUT}`]: '',
    [`${WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE}`]: '',
    [`${WEAPON_TYPE.KNIFE_KARAMBIT}`]: '',
    [`${WEAPON_TYPE.KNIFE_M9_BAYONET}`]: '',
    [`${WEAPON_TYPE.KNIFE_OUTDOOR}`]: '',
    [`${WEAPON_TYPE.KNIFE_PUSH}`]: '',
    [`${WEAPON_TYPE.KNIFE_SKELETON}`]: '',
    [`${WEAPON_TYPE.KNIFE_STILETTO}`]: '',
    [`${WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE}`]: '',
    [`${WEAPON_TYPE.KNIFE_TACTICAL}`]: '',
    [`${WEAPON_TYPE.KNIFE_URSUS}`]: '',
    [`${WEAPON_TYPE.KNIFE_KUKRI}`]: '',
    [`${WEAPON_TYPE.KNIFE_WIDOWMAKER}`]: '',
    [`${WEAPON_TYPE.M4A1}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_m4a1.png',
    [`${WEAPON_TYPE.M4A1_SILENCER}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_m4a1_silencer.png',
    [`${WEAPON_TYPE.M249}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_m249.png',
    [`${WEAPON_TYPE.MAC10}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_mac10.png',
    [`${WEAPON_TYPE.MAG7}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_mag7.png',
    [`${WEAPON_TYPE.MP5SD}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_mp5sd.png',
    [`${WEAPON_TYPE.MP7}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_mp7.png',
    [`${WEAPON_TYPE.MP9}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_mp9.png',
    [`${WEAPON_TYPE.NEGEV}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_negev.png',
    [`${WEAPON_TYPE.NOVA}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_nova.png',
    [`${WEAPON_TYPE.P90}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_p90.png',
    [`${WEAPON_TYPE.P250}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_p250.png',
    [`${WEAPON_TYPE.REVOLVER}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_revolver.png',
    [`${WEAPON_TYPE.SAWEDOFF}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_sawedoff.png',
    [`${WEAPON_TYPE.SCAR20}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_scar20.png',
    [`${WEAPON_TYPE.SG556}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_sg556.png',
    [`${WEAPON_TYPE.SSG08}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_ssg08.png',
    [`${WEAPON_TYPE.TEC9}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_tec9.png',
    [`${WEAPON_TYPE.UMP45}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_ump45.png',
    [`${WEAPON_TYPE.USP_SILENCER}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_usp_silencer.png',
    [`${WEAPON_TYPE.XM1014}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_xm1014.png',
    [`${WEAPON_TYPE.TASER}`]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_taser.png',
};
