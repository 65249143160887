/* eslint-disable no-console */
import { IChest } from '../../types/chest';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import Button from '../../shared/components/Button';
import Divider from '../../shared/components/Divider';
import DrawItems from '../../shared/components/DrawItems';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import SkinPreview from '../../shared/components/SkinPreview';
import axios from 'axios';
import styles from './ChestDetails.module.scss';

const ChestDetails = () => {
    const [, setLoading] = useState(true);
    const [data, setData] = useState<IChest | null>(null);
    const navigate = useNavigate();
    const { caseId } = useParams();

    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .get(`/api/get-chest/${caseId}`)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    }, [caseId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={styles.wrapper}>
            <Button name="POWRÓT" onClick={() => navigate('/chests')} />
            {data ? <Divider name={data.name.toUpperCase()} nameColor="#fff" /> : null}
            {data && (<div><DrawItems chestDetails={data} /></div>)}
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                    {data ? data.items.map((item, idx) => (
                        <div key={idx} onClick={() => navigate(`/chests/${data.id}/item/${item.id}`)}>
                            <SkinPreview
                                chance={item.chance}
                                icon={item.icon}
                                id={item.id}
                                maxPrice={item.maxPrice}
                                minPrice={item.minPrice}
                                name={item.name}
                                rarity={item.rarity}
                                weaponType={item.weaponType}
                            />
                        </div>
                    )) : null}
                </div>
            </div>
        </div>
    );
};

export default ChestDetails;
