import {
    Route,
    Routes,
    useLocation,
    useNavigate,
} from 'react-router-dom';
import AccountPanelNavigation from '../AccountPanelNavigation';
import Container from '@mui/material/Container';
import Equipment from '../Equipment';
import MyAccount from '../MyAccount';
import MyEquipment from '../MyEquipment';
import React, {
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import Settings from '../Settings';

const AccountPanel = () => {
    const [value, setValue] = useState(undefined as unknown as number);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const changeTab = useCallback(
        (tabValue: SetStateAction<number>) => {
            if (tabValue === 0) {
                navigate('/panel/my-account');
            }
            if (tabValue === 1) {
                navigate('/panel/equipment');
            }
            if (tabValue === 2) {
                navigate('/panel/my-weapons');
            }
            if (tabValue === 3) {
                navigate('/panel/settings');
            }
        },
        [navigate],
    );

    // Navigate after value change
    useEffect(
        () => {
            if (pathname === '/panel/my-account') {
                setValue(0);
            }
            if (pathname === '/panel/equipment') {
                setValue(1);
            }
            if (pathname === '/panel/my-weapons') {
                setValue(2);
            }
            if (pathname === '/panel/settings') {
                setValue(3);
            }
        },
        [pathname],
    );

    return (
        <>
            <Container maxWidth="xl" sx={{ display: 'flex', justifyContent: 'center' }}>
                <AccountPanelNavigation setValue={changeTab} value={value} />
            </Container>
            <Routes>
                <Route element={<MyAccount />} path="/my-account" />
                <Route element={<Equipment />} path="/equipment" />
                <Route element={<MyEquipment />} path="/my-weapons" />
                <Route element={<Settings />} path="/settings" />
            </Routes>
        </>
    );
};

export default AccountPanel;
