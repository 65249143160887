import React from 'react';
import styles from './Roles.module.scss';

const Roles = () => {

    return (
        <div className={styles.wrapper}>
            Roles
        </div>
    );
};

export default Roles;
