/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable max-len */
import { IChest } from '../../types/chest';
import { IWeaponTemplate } from '../../types/WeaponTemplate';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import WikiSearchField from './WikiSearchField';

interface Column {
    align?: 'right';
    format?: (value: number) => string;
    id: 'plName' | 'weaponType' | 'rarity' | 'itemIcon';
    label: string;
    minWidth?: number;
}

const columns: readonly Column[] = [
    { id: 'itemIcon', label: '', minWidth: 170 },
    { id: 'plName', label: 'Nazwa', minWidth: 170 },
    { id: 'weaponType', label: 'Typ', minWidth: 100 },
    { id: 'rarity', label: 'Klasa', minWidth: 100 },
];

interface IProps {
    clickable?: boolean;
    items: IWeaponTemplate[];
    limit: number;
    loading: boolean;
    onRowClick?: (row: IChest) => void;
    page: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    setWeaponTypeValue: React.Dispatch<React.SetStateAction<string>>;
    totalItems: number;
}

const WikiTable = ({
    clickable,
    items,
    limit,
    loading,
    onRowClick,
    page,
    setLimit,
    setPage,
    setSearchValue,
    setWeaponTypeValue,
    totalItems,
}: IProps) => {
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLimit(+event.target.value);
    };

    const rows = items.map((item) => ({
        id: item._id,
        itemIcon: item.itemIcon,
        plName: item.plName,
        rarity: item.rarity,
        weaponType: item.weaponType,
    }));

    return (
        <Paper sx={{ overflow: 'hidden', width: '100%' }}>
            <Box display="flex" justifyContent="end">
                <WikiSearchField
                    setSearchValue={setSearchValue}
                    setWeaponTypeValue={setWeaponTypeValue}
                />
            </Box>
            <TableContainer sx={{ height: 540 }}>
                <Table aria-label="smart-table" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell align={column.align} key={column.id} style={{ minWidth: column.minWidth }}>
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.id}
                                    onClick={clickable && onRowClick ? () => onRowClick(row as any) : () => {}}
                                    role="checkbox"
                                    style={{ cursor: clickable ? 'pointer' : 'default' }}
                                    tabIndex={-1}
                                >
                                    {columns.map((column) => {
                                        const value = row[column.id];

                                        if (column.id === 'itemIcon') {
                                            return (
                                                <TableCell align={column.align} key={column.id}>
                                                    <Avatar alt="Item icon" src={value} sx={{ height: 66, width: 66 }} variant="square" />
                                                </TableCell>
                                            );
                                        }

                                        return (
                                            <TableCell align={column.align} key={column.id}>
                                                {column.format && typeof value === 'number' ? column.format(value) : value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                SelectProps={{
                    disabled: loading,
                }}
                backIconButtonProps={{
                    disabled: loading || page === 0,
                }}
                component="div"
                count={totalItems}
                nextIconButtonProps={{
                    disabled: loading || totalItems - limit * page < limit,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[10, 20, 50, 100]}
            />
        </Paper>
    );
};

export default WikiTable;
