import {
    GLOVES,
    KNIFES,
    TEAM,
    WEAPON_TYPE,
} from '../../types/enums';

export const WEAPONS_CONFIG = {
    midTier: {
        [TEAM.CT]: [
            WEAPON_TYPE.MP7,
            WEAPON_TYPE.MP9,
            WEAPON_TYPE.UMP45,
            WEAPON_TYPE.MP5SD,
            WEAPON_TYPE.P90,
            WEAPON_TYPE.BIZON,
            WEAPON_TYPE.NOVA,
            WEAPON_TYPE.XM1014,
            WEAPON_TYPE.MAG7,
            WEAPON_TYPE.NEGEV,
            WEAPON_TYPE.M249,
        ],
        [TEAM.TT]: [
            WEAPON_TYPE.MP7,
            WEAPON_TYPE.MAC10,
            WEAPON_TYPE.UMP45,
            WEAPON_TYPE.MP5SD,
            WEAPON_TYPE.P90,
            WEAPON_TYPE.BIZON,
            WEAPON_TYPE.NOVA,
            WEAPON_TYPE.XM1014,
            WEAPON_TYPE.SAWEDOFF,
            WEAPON_TYPE.NEGEV,
            WEAPON_TYPE.M249,
        ],
    },
    pistols: {
        [TEAM.CT]: [
            WEAPON_TYPE.USP_SILENCER,
            WEAPON_TYPE.HKP2000,
            WEAPON_TYPE.P250,
            WEAPON_TYPE.FIVESEVEN,
            WEAPON_TYPE.DEAGLE,
            WEAPON_TYPE.ELITE,
            WEAPON_TYPE.REVOLVER,
        ],
        [TEAM.TT]: [
            WEAPON_TYPE.GLOCK,
            WEAPON_TYPE.P250,
            WEAPON_TYPE.TEC9,
            WEAPON_TYPE.DEAGLE,
            WEAPON_TYPE.ELITE,
            WEAPON_TYPE.REVOLVER,
        ],
    },
    rifles: {
        [TEAM.CT]: [
            WEAPON_TYPE.FAMAS,
            WEAPON_TYPE.M4A1_SILENCER,
            WEAPON_TYPE.M4A1,
            WEAPON_TYPE.AK47,
            WEAPON_TYPE.AUG,
            WEAPON_TYPE.SSG08,
            WEAPON_TYPE.AWP,
            WEAPON_TYPE.SCAR20,
        ],
        [TEAM.TT]: [
            WEAPON_TYPE.GALILAR,
            WEAPON_TYPE.M4A1_SILENCER,
            WEAPON_TYPE.M4A1,
            WEAPON_TYPE.AK47,
            WEAPON_TYPE.SG556,
            WEAPON_TYPE.SSG08,
            WEAPON_TYPE.AWP,
            WEAPON_TYPE.G3SG1,
        ],
    },
};

export const EQUIPMENT_CONFIG = {
    agent: {},
    gloves: {
        [TEAM.CT]: [...GLOVES],
        [TEAM.TT]: [...GLOVES],
    },
    knife: {
        [TEAM.CT]: [...KNIFES],
        [TEAM.TT]: [...KNIFES],
    },
    weapons: WEAPONS_CONFIG,
};
