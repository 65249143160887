/* eslint-disable complexity */
import { ITEM_RARITY } from '../../../types/enums';
import { IWeaponTemplate } from '../../../types/WeaponTemplate';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './SkinInfo.module.scss';

const SkinInfo = ({ itemIcon, plName, price, rarity }: Partial<IWeaponTemplate>) => {
    const [selectedFloat, setSelectedFloat] = useState(0);

    const classes = (itemRarity: ITEM_RARITY) => ({
        [ITEM_RARITY.CLASSIFIED]: styles.classGold,
        [ITEM_RARITY.CONSUMER_GRADE]: styles.classWhite,
        [ITEM_RARITY.CONTRABAND]: styles.classPurple,
        [ITEM_RARITY.COVERT]: styles.classRed,
        [ITEM_RARITY.INDUSTRIAL_GRADE]: styles.classLightBlue,
        [ITEM_RARITY.MIL_SPEC]: styles.classBlue,
        [ITEM_RARITY.RESTRICTED]: styles.classPink,
    })[itemRarity];

    return (
        <div className={styles.wrapper}>
            <div className={classNames(styles.column, styles.img, classes(rarity || '' as ITEM_RARITY))}>
                <img alt="item" src={itemIcon} />
            </div>
            <div className={styles.column}>
                <div className={styles.itemName}>{plName?.toUpperCase()}</div>
                <div className={styles.itemsFloatWrapper}>
                    <div
                        className={classNames(styles.itemFloatBox, selectedFloat === 0 ? styles.selectedFloat : '')}
                        onClick={() => setSelectedFloat(0)}
                    >
                    Factory new
                    </div>
                    <div
                        className={classNames(styles.itemFloatBox, selectedFloat === 1 ? styles.selectedFloat : '')}
                        onClick={() => setSelectedFloat(1)}
                    >
                    Minimal wear
                    </div>
                    <div
                        className={classNames(styles.itemFloatBox, selectedFloat === 2 ? styles.selectedFloat : '')}
                        onClick={() => setSelectedFloat(2)}
                    >
                    Field tested
                    </div>
                    <div
                        className={classNames(styles.itemFloatBox, selectedFloat === 3 ? styles.selectedFloat : '')}
                        onClick={() => setSelectedFloat(3)}
                    >
                    Well worn
                    </div>
                    <div
                        className={classNames(styles.itemFloatBox, selectedFloat === 4 ? styles.selectedFloat : '')}
                        onClick={() => setSelectedFloat(4)}
                    >
                    Battle scarred
                    </div>
                </div>
            </div>
            <div className={classNames(styles.column, styles.priceWrapper)}>
                <div className={styles.summaryTitle}>Cena</div>
                <div className={classNames(styles.priceRow, selectedFloat === 0 ? styles.selectedFloat : '')}>
                    <div>Factory new:</div>
                    <div>{`${price?.factoryNew} PLN`}</div>
                </div>
                <div className={classNames(styles.priceRow, selectedFloat === 1 ? styles.selectedFloat : '')}>
                    <div>Minimal wear:</div>
                    <div>{`${price?.minimalWear} PLN`}</div>
                </div>
                <div className={classNames(styles.priceRow, selectedFloat === 2 ? styles.selectedFloat : '')}>
                    <div>Field tested:</div>
                    <div>{`${price?.fieldTested} PLN`}</div>
                </div>
                <div className={classNames(styles.priceRow, selectedFloat === 3 ? styles.selectedFloat : '')}>
                    <div>Well worn:</div>
                    <div>{`${price?.wellWorn} PLN`}</div>
                </div>
                <div className={classNames(styles.priceRow, selectedFloat === 4 ? styles.selectedFloat : '')}>
                    <div>Battle scarred:</div>
                    <div>{`${price?.battleScarred} PLN`}</div>
                </div>
            </div>
        </div>
    );
};

export default SkinInfo;
