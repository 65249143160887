import {
    EQ_TYPE,
    TEAM,
} from '../../../../types/enums';
import { IEquipmentResponse } from '../../../../types/Equipment';
import React from 'react';
import classNames from 'classnames';
import styles from './OtherEq.module.scss';

const DEFAULT_IMAGES = {
    [EQ_TYPE.AGENT]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/agent-4714.png',
    [EQ_TYPE.GLOVES]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/slick_gloves-10044.png',
    [EQ_TYPE.KNIFE]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_knife_css.png',
    [EQ_TYPE.MUSIC]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/music_kit-1.png',
    [EQ_TYPE.PIN]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/collectible-875.png',
    [EQ_TYPE.ZEUS]: 'https://raw.githubusercontent.com/Nereziel/cs2-WeaponPaints/main/website/img/skins/weapon_taser-292.png',
};

interface IOtherEq {
    clickable: boolean;
    onClick: (type: EQ_TYPE) => void;
    team: TEAM;
    usedEq: {
        [EQ_TYPE.AGENT]?: IEquipmentResponse;
        [EQ_TYPE.GLOVES]?: IEquipmentResponse;
        [EQ_TYPE.KNIFE]?: IEquipmentResponse;
        [EQ_TYPE.MUSIC]?: IEquipmentResponse;
        [EQ_TYPE.PIN]?: IEquipmentResponse;
        [EQ_TYPE.ZEUS]?: IEquipmentResponse;
    };
}

export const OtherEq = (
    {
        clickable,
        onClick,
        team,
        usedEq,
    }: IOtherEq,
) => {

    const EqItem = ({ type }: { type: EQ_TYPE }) => {
        const item = usedEq[type];

        return (
            <div className={classNames(
                styles.eqItemWrapper,
                clickable && styles.clickable,
            )}>
                <div className={styles.eqItem}>
                    {item
                        ? (
                            <img
                                alt="weaponType"
                                className={styles.itemImg}
                                onClick={() => clickable && onClick(type)}
                                src={item.icon}
                            />
                        )
                        : (
                            <img
                                alt="weaponType"
                                className={classNames(
                                    styles.defaultImg,
                                    styles[`defaultImg_${type}`],
                                )}
                                onClick={() => clickable && onClick(type)}
                                src={DEFAULT_IMAGES[type]}
                            />
                        )
                    }
                </div>
            </div>
        );
    };

    return (
        <div
            className={styles.wrapper}
            key={team}
        >
            <EqItem type={EQ_TYPE.AGENT} />
            <EqItem type={EQ_TYPE.GLOVES} />
            <EqItem type={EQ_TYPE.KNIFE} />
            <EqItem type={EQ_TYPE.MUSIC} />
            <EqItem type={EQ_TYPE.PIN} />
            <EqItem type={EQ_TYPE.ZEUS} />
        </div>
    );
};

export default OtherEq;
