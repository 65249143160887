import { IEquipmentResponse } from '../../../../types/Equipment';
import { WEAPON_TYPE } from '../../../../types/enums';
import React from 'react';
import styles from './EquippedItemPreview.module.scss';
import weaponsDefaultImages from '../../../../shared/weaponsDefaultImages';

interface IEquippedItemPreview {
    customItem?: IEquipmentResponse;
    onClick: () => void;
    weaponType: WEAPON_TYPE;
}

export const EquippedItemPreview = (
    {
        customItem,
        onClick,
        weaponType,
    }: IEquippedItemPreview,
) => {
    return (
        <div
            className={styles.wrapper}
            onClick={onClick}
        >
            <img
                alt="weaponType"
                className={styles.image}
                src={customItem?.icon || weaponsDefaultImages[weaponType]}
            />
        </div>
    );
};

export default EquippedItemPreview;
