import { WEAPON_TYPE } from '../types/enums';
import weaponsDefaultImages from './weaponsDefaultImages';

export default {
    [`${WEAPON_TYPE.AK47}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.AK47],
        name: {
            en: 'ak47',
            pl: 'ak47',
        },
    },
    [`${WEAPON_TYPE.AUG}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.AUG],
        name: {
            en: 'aug',
            pl: 'aug',
        },
    },
    [`${WEAPON_TYPE.AWP}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.AWP],
        name: {
            en: 'awp',
            pl: 'awp',
        },
    },
    [`${WEAPON_TYPE.BAYONET}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.BAYONET],
        name: {
            en: 'bayonet (★)',
            pl: 'bagnet (★)',
        },
    },
    [`${WEAPON_TYPE.BIZON}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.BIZON],
        name: {
            en: 'pp-bizon',
            pl: 'pp-bizon',
        },
    },
    [`${WEAPON_TYPE.CZ75A}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.CZ75A],
        name: {
            en: 'cz75a',
            pl: 'cz75a',
        },
    },
    [`${WEAPON_TYPE.DEAGLE}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.DEAGLE],
        name: {
            en: 'desert eagle',
            pl: 'desert eagle',
        },
    },
    [`${WEAPON_TYPE.ELITE}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.ELITE],
        name: {
            en: 'dual berets',
            pl: 'podwójne beretty',
        },
    },
    [`${WEAPON_TYPE.FAMAS}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.FAMAS],
        name: {
            en: 'famas',
            pl: 'famas',
        },
    },
    [`${WEAPON_TYPE.FIVESEVEN}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.FIVESEVEN],
        name: {
            en: 'fiveseven',
            pl: 'fiveseven',
        },
    },
    [`${WEAPON_TYPE.G3SG1}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.G3SG1],
        name: {
            en: 'g3sg1',
            pl: 'g3sg1',
        },
    },
    [`${WEAPON_TYPE.GALILAR}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.GALILAR],
        name: {
            en: 'galil ar',
            pl: 'galil ar',
        },
    },
    [`${WEAPON_TYPE.GLOCK}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.GLOCK],
        name: {
            en: 'glock',
            pl: 'glock',
        },
    },
    [`${WEAPON_TYPE.GLOVES_BLOODHOUND}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Bloodhound Gloves (★)',
            pl: 'Bloodhound Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_BROKEN_FANG}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Broken Fang Gloves (★)',
            pl: 'Broken Fang Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_DRIVER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Driver Gloves (★)',
            pl: 'Driver Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_HAND_WRAPS}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Hand Wraps (★)',
            pl: 'Hand Wraps (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_HYDRA}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Hydra Gloves (★)',
            pl: 'Hydra Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_MOTO}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Moto Gloves (★)',
            pl: 'Moto Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_SPECIALIST}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Specialist Gloves (★)',
            pl: 'Specialist Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.GLOVES_SPORT}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'Sport Gloves (★)',
            pl: 'Sport Gloves (★)',
        },
    },
    [`${WEAPON_TYPE.HKP2000}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.HKP2000],
        name: {
            en: 'p2000',
            pl: 'p2000',
        },
    },
    [`${WEAPON_TYPE.KNIFE_BUTTERFLY}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_BUTTERFLY],
        name: {
            en: 'knife butterfly (★)',
            pl: 'nóż motylkowy (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_CANIS}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_CANIS],
        name: {
            en: 'survival knife (★)',
            pl: 'nóż survivalowy (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_CORD}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_CORD],
        name: {
            en: 'paracord knife (★)',
            pl: 'nóż paracord (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_CSS}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_CSS],
        name: {
            en: 'classic knife (★)',
            pl: 'klasyczny nóż (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_FALCHION}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_FALCHION],
        name: {
            en: 'falchion knife (★)',
            pl: 'nóż falchion (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_FLIP}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_FLIP],
        name: {
            en: 'flip knife (★)',
            pl: 'nóż składany (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_GUT}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_GUT],
        name: {
            en: 'gut knife (★)',
            pl: 'nóż z hakiem (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE],
        name: {
            en: 'navaja (★)',
            pl: 'navaja (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_KARAMBIT}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_KARAMBIT],
        name: {
            en: 'karambit (★)',
            pl: 'karambit (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_KUKRI}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.XM1014],
        name: {
            en: 'kukri (★)',
            pl: 'kukri (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_M9_BAYONET}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_M9_BAYONET],
        name: {
            en: 'm9 bayonet (★)',
            pl: 'bagnet m9 (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_OUTDOOR}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_OUTDOOR],
        name: {
            en: 'nomad knife (★)',
            pl: 'nóż nomada (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_PUSH}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_PUSH],
        name: {
            en: 'shadow daggers (★)',
            pl: 'sztylety cienia (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_SKELETON}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_SKELETON],
        name: {
            en: 'skeleton knife (★)',
            pl: 'nóż szkieletowy (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_STILETTO}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_STILETTO],
        name: {
            en: 'stiletto (★)',
            pl: 'sztylet (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE],
        name: {
            en: 'bowie knife (★)',
            pl: 'nóż bowie (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_TACTICAL}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_TACTICAL],
        name: {
            en: 'huntsman knife (★)',
            pl: 'nóż myśliwski (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_URSUS}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_URSUS],
        name: {
            en: 'ursus (★)',
            pl: 'ursus (★)',
        },
    },
    [`${WEAPON_TYPE.KNIFE_WIDOWMAKER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.KNIFE_WIDOWMAKER],
        name: {
            en: 'talon knife (★)',
            pl: 'szpon (★)',
        },
    },
    [`${WEAPON_TYPE.M4A1}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.M4A1],
        name: {
            en: 'm4a1',
            pl: 'm4a1',
        },
    },
    [`${WEAPON_TYPE.M4A1_SILENCER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.M4A1_SILENCER],
        name: {
            en: 'm4a1-s',
            pl: 'm4a1-s',
        },
    },
    [`${WEAPON_TYPE.M249}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.M249],
        name: {
            en: 'm249',
            pl: 'm249',
        },
    },
    [`${WEAPON_TYPE.MAC10}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.MAC10],
        name: {
            en: 'mac10',
            pl: 'mac10',
        },
    },
    [`${WEAPON_TYPE.MAG7}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.MAG7],
        name: {
            en: 'mag7',
            pl: 'mag7',
        },
    },
    [`${WEAPON_TYPE.MP5SD}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.MP5SD],
        name: {
            en: 'mp5',
            pl: 'mp5',
        },
    },
    [`${WEAPON_TYPE.MP7}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.MP7],
        name: {
            en: 'mp7',
            pl: 'mp7',
        },
    },
    [`${WEAPON_TYPE.MP9}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.MP9],
        name: {
            en: 'mp9',
            pl: 'mp9',
        },
    },
    [`${WEAPON_TYPE.NEGEV}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.NEGEV],
        name: {
            en: 'negev',
            pl: 'negev',
        },
    },
    [`${WEAPON_TYPE.NOVA}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.NOVA],
        name: {
            en: 'nova',
            pl: 'nova',
        },
    },
    [`${WEAPON_TYPE.P90}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.P90],
        name: {
            en: 'p90',
            pl: 'p90',
        },
    },
    [`${WEAPON_TYPE.P250}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.P250],
        name: {
            en: 'p250',
            pl: 'p250',
        },
    },
    [`${WEAPON_TYPE.REVOLVER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.REVOLVER],
        name: {
            en: 'revolver r8',
            pl: 'rewolwer r8',
        },
    },
    [`${WEAPON_TYPE.SAWEDOFF}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.SAWEDOFF],
        name: {
            en: 'sawedoff',
            pl: 'obrzyn',
        },
    },
    [`${WEAPON_TYPE.SCAR20}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.SCAR20],
        name: {
            en: 'scar20',
            pl: 'scar20',
        },
    },
    [`${WEAPON_TYPE.SG556}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.SG556],
        name: {
            en: 'sg556',
            pl: 'sg556',
        },
    },
    [`${WEAPON_TYPE.SSG08}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.SSG08],
        name: {
            en: 'ssg08',
            pl: 'ssg08',
        },
    },
    [`${WEAPON_TYPE.TASER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.XM1014],
        name: {
            en: 'Zeus',
            pl: 'Zeus',
        },
    },
    [`${WEAPON_TYPE.TEC9}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.TEC9],
        name: {
            en: 'tec9',
            pl: 'tec9',
        },
    },
    [`${WEAPON_TYPE.UMP45}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.UMP45],
        name: {
            en: 'ump45',
            pl: 'ump45',
        },
    },
    [`${WEAPON_TYPE.USP_SILENCER}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.USP_SILENCER],
        name: {
            en: 'usp-s',
            pl: 'usp-s',
        },
    },
    [`${WEAPON_TYPE.XM1014}`]: {
        image: weaponsDefaultImages[WEAPON_TYPE.XM1014],
        name: {
            en: 'xm1014',
            pl: 'xm1014',
        },
    },
};
