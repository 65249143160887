export enum ROLE {
    ADMIN = 'admin',
    MODERATOR = 'moderator',
    USER = 'user',
}

export enum TEAM {
    CT = 'ct',
    TT = 'tt',
}

export enum WEAPONS_GROUP {
    MID_TIER = 'midTier',
    PISTOLS = 'pistols',
    RIFLES = 'rifles',
}

export enum EQ_TYPE {
    AGENT = 'agent',
    GLOVES = 'gloves',
    KNIFE = 'knife',
    MUSIC = 'music',
    PIN = 'pin',
    ZEUS = 'zeus',
}

export enum ITEM_WEAR {
    BATTLE_SCARRED = 'battleScarred',
    FACTORY_NEW = 'factoryNew',
    FIELD_TESTED = 'fieldTested',
    MINIMAL_WEAR = 'minimalWear',
    WELL_WORN = 'wellWorn',
}

export const ITEM_WEAR_SHORT = {
    [ITEM_WEAR.BATTLE_SCARRED]: 'bs',
    [ITEM_WEAR.FACTORY_NEW]: 'fn',
    [ITEM_WEAR.FIELD_TESTED]: 'ft',
    [ITEM_WEAR.MINIMAL_WEAR]: 'mw',
    [ITEM_WEAR.WELL_WORN]: 'ww',
};

export enum ITEM_CLASS {
    BLUE = 'BLUE',
    GOLD = 'GOLD',
    LIGHT_BLUE = 'LIGHT_BLUE',
    PINK = 'PINK',
    PURPLE = 'PURPLE',
    RED = 'RED',
    WHITE = 'WHITE',
}

export enum ITEM_RARITY {
    CLASSIFIED = 'classified',
    CONSUMER_GRADE = 'consumer',
    CONTRABAND = 'contraband',
    COVERT = 'covert',
    INDUSTRIAL_GRADE = 'industrial',
    MIL_SPEC = 'milSpec',
    RESTRICTED = 'restricted',
    // STOCK = 'stock',
}

export enum WEAPON_TYPE {
    AK47 = 'weapon_ak47',
    AUG = 'weapon_aug',
    AWP = 'weapon_awp',
    BAYONET = 'weapon_bayonet',
    BIZON = 'weapon_bizon',
    CZ75A = 'weapon_cz75a',
    DEAGLE = 'weapon_deagle',
    ELITE = 'weapon_elite',
    FAMAS = 'weapon_famas',
    FIVESEVEN = 'weapon_fiveseven',
    G3SG1 = 'weapon_g3sg1',
    GALILAR = 'weapon_galilar',
    GLOCK = 'weapon_glock',
    GLOVES_BLOODHOUND = 'weapon_gloves_bloodhound',
    GLOVES_BROKEN_FANG = 'weapon_gloves_broken_fang',
    GLOVES_DRIVER = 'weapon_gloves_driver_gloves',
    GLOVES_HAND_WRAPS = 'weapon_gloves_hand_wraps',
    GLOVES_HYDRA = 'weapon_gloves_hydra',
    GLOVES_MOTO = 'weapon_gloves_moto_gloves',
    GLOVES_SPECIALIST = 'weapon_gloves_specialist',
    GLOVES_SPORT = 'weapon_gloves_sport',
    HKP2000 = 'weapon_hkp2000',
    KNIFE_BUTTERFLY = 'weapon_knife_butterfly',
    KNIFE_CANIS = 'weapon_knife_canis',
    KNIFE_CORD = 'weapon_knife_cord',
    KNIFE_CSS = 'weapon_knife_css',
    KNIFE_FALCHION = 'weapon_knife_falchion',
    KNIFE_FLIP = 'weapon_knife_flip',
    KNIFE_GUT = 'weapon_knife_gut',
    KNIFE_GYPSY_JACKKNIFE = 'weapon_knife_gypsy_jackknife',
    KNIFE_KARAMBIT = 'weapon_knife_karambit',
    KNIFE_KUKRI = 'weapon_knife_kukri',
    KNIFE_M9_BAYONET = 'weapon_knife_m9_bayonet',
    KNIFE_OUTDOOR = 'weapon_knife_outdoor',
    KNIFE_PUSH = 'weapon_knife_push',
    KNIFE_SKELETON = 'weapon_knife_skeleton',
    KNIFE_STILETTO = 'weapon_knife_stiletto',
    KNIFE_SURVIVAL_BOWIE = 'weapon_knife_survival_bowie',
    KNIFE_TACTICAL = 'weapon_knife_tactical',
    KNIFE_URSUS = 'weapon_knife_ursus',
    KNIFE_WIDOWMAKER = 'weapon_knife_widowmaker',
    M249 = 'weapon_m249',
    M4A1 = 'weapon_m4a1',
    M4A1_SILENCER = 'weapon_m4a1_silencer',
    MAC10 = 'weapon_mac10',
    MAG7 = 'weapon_mag7',
    MP5SD = 'weapon_mp5sd',
    MP7 = 'weapon_mp7',
    MP9 = 'weapon_mp9',
    NEGEV = 'weapon_negev',
    NOVA = 'weapon_nova',
    P250 = 'weapon_p250',
    P90 = 'weapon_p90',
    REVOLVER = 'weapon_revolver',
    SAWEDOFF = 'weapon_sawedoff',
    SCAR20 = 'weapon_scar20',
    SG556 = 'weapon_sg556',
    SSG08 = 'weapon_ssg08',
    TASER = 'weapon_taser',
    TEC9 = 'weapon_tec9',
    UMP45 = 'weapon_ump45',
    USP_SILENCER = 'weapon_usp_silencer',
    XM1014 = 'weapon_xm1014',
}

export const KNIFES = [
    WEAPON_TYPE.BAYONET,
    WEAPON_TYPE.KNIFE_BUTTERFLY,
    WEAPON_TYPE.KNIFE_CANIS,
    WEAPON_TYPE.KNIFE_CORD,
    WEAPON_TYPE.KNIFE_CSS,
    WEAPON_TYPE.KNIFE_FALCHION,
    WEAPON_TYPE.KNIFE_FLIP,
    WEAPON_TYPE.KNIFE_GUT,
    WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE,
    WEAPON_TYPE.KNIFE_KARAMBIT,
    WEAPON_TYPE.KNIFE_M9_BAYONET,
    WEAPON_TYPE.KNIFE_OUTDOOR,
    WEAPON_TYPE.KNIFE_PUSH,
    WEAPON_TYPE.KNIFE_SKELETON,
    WEAPON_TYPE.KNIFE_STILETTO,
    WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE,
    WEAPON_TYPE.KNIFE_TACTICAL,
    WEAPON_TYPE.KNIFE_URSUS,
    WEAPON_TYPE.KNIFE_WIDOWMAKER,
    WEAPON_TYPE.KNIFE_KUKRI,
];

export const KNIFES_INDEX = {
    [WEAPON_TYPE.KNIFE_CORD]: 49,
    [WEAPON_TYPE.KNIFE_CANIS]: 50,
    [WEAPON_TYPE.KNIFE_OUTDOOR]: 51,
    [WEAPON_TYPE.KNIFE_SKELETON]: 52,
    [WEAPON_TYPE.KNIFE_CSS]: 48,
    [WEAPON_TYPE.KNIFE_URSUS]: 43,
    [WEAPON_TYPE.KNIFE_STILETTO]: 45,
    [WEAPON_TYPE.KNIFE_WIDOWMAKER]: 46,
    [WEAPON_TYPE.KNIFE_KARAMBIT]: 33,
    [WEAPON_TYPE.KNIFE_M9_BAYONET]: 34,
    [WEAPON_TYPE.BAYONET]: 35,
    [WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE]: 36,
    [WEAPON_TYPE.KNIFE_BUTTERFLY]: 37,
    [WEAPON_TYPE.KNIFE_FLIP]: 38,
    [WEAPON_TYPE.KNIFE_PUSH]: 39,
    [WEAPON_TYPE.KNIFE_TACTICAL]: 40,
    [WEAPON_TYPE.KNIFE_FALCHION]: 41,
    [WEAPON_TYPE.KNIFE_GUT]: 42,
    [WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE]: 44,
    [WEAPON_TYPE.KNIFE_KUKRI]: 53,
};

export const GLOVES = [
    WEAPON_TYPE.GLOVES_BLOODHOUND,
    WEAPON_TYPE.GLOVES_BROKEN_FANG,
    WEAPON_TYPE.GLOVES_DRIVER,
    WEAPON_TYPE.GLOVES_HAND_WRAPS,
    WEAPON_TYPE.GLOVES_HYDRA,
    WEAPON_TYPE.GLOVES_MOTO,
    WEAPON_TYPE.GLOVES_SPECIALIST,
    WEAPON_TYPE.GLOVES_SPORT,
];

export const GLOVES_INDEX = {
    [WEAPON_TYPE.GLOVES_BLOODHOUND]: 5027,
    [WEAPON_TYPE.GLOVES_BROKEN_FANG]: 4725,
    [WEAPON_TYPE.GLOVES_DRIVER]: 5031,
    [WEAPON_TYPE.GLOVES_HAND_WRAPS]: 5032,
    [WEAPON_TYPE.GLOVES_HYDRA]: 5035,
    [WEAPON_TYPE.GLOVES_MOTO]: 5033,
    [WEAPON_TYPE.GLOVES_SPECIALIST]: 5034,
    [WEAPON_TYPE.GLOVES_SPORT]: 5030,
};

export const WEAPON_STEAM_NAME = {
    [WEAPON_TYPE.AK47]: 'AK-47',
    [WEAPON_TYPE.AUG]: 'AUG',
    [WEAPON_TYPE.AWP]: 'AWP',
    [WEAPON_TYPE.BIZON]: 'PP-Bizon',
    [WEAPON_TYPE.CZ75A]: 'CZ75-Auto',
    [WEAPON_TYPE.DEAGLE]: 'Desert%20Eagle',
    [WEAPON_TYPE.ELITE]: 'Dual%20Berettas',
    [WEAPON_TYPE.FAMAS]: 'FAMAS',
    [WEAPON_TYPE.FIVESEVEN]: 'Five-SeveN',
    [WEAPON_TYPE.G3SG1]: 'G3SG1',
    [WEAPON_TYPE.GALILAR]: 'Galil%20AR',
    [WEAPON_TYPE.GLOCK]: 'Glock-18',
    [WEAPON_TYPE.GLOVES_BLOODHOUND]: '★%20Bloodhound Gloves',
    [WEAPON_TYPE.GLOVES_BROKEN_FANG]: '★%20Broken Fang Gloves',
    [WEAPON_TYPE.GLOVES_DRIVER]: '★%20Driver Gloves',
    [WEAPON_TYPE.GLOVES_HAND_WRAPS]: '★%20Hand Wraps',
    [WEAPON_TYPE.GLOVES_HYDRA]: '★%20Hydra Gloves',
    [WEAPON_TYPE.GLOVES_MOTO]: '★%20Moto Gloves',
    [WEAPON_TYPE.GLOVES_SPECIALIST]: '★%20Specialist Gloves',
    [WEAPON_TYPE.GLOVES_SPORT]: '★%20Sport Gloves',
    [WEAPON_TYPE.HKP2000]: 'P2000',
    [WEAPON_TYPE.M249]: 'M249',
    [WEAPON_TYPE.M4A1]: 'M4A4',
    [WEAPON_TYPE.M4A1_SILENCER]: 'M4A1-S',
    [WEAPON_TYPE.MAC10]: 'MAC-10',
    [WEAPON_TYPE.MAG7]: 'MAG-7',
    [WEAPON_TYPE.MP5SD]: 'MP5-SD',
    [WEAPON_TYPE.MP7]: 'MP7',
    [WEAPON_TYPE.MP9]: 'MP9',
    [WEAPON_TYPE.NEGEV]: 'Negev',
    [WEAPON_TYPE.NOVA]: 'Nova',
    [WEAPON_TYPE.P250]: 'P250',
    [WEAPON_TYPE.P90]: 'P90',
    [WEAPON_TYPE.REVOLVER]: 'R8%20Revolver',
    [WEAPON_TYPE.SAWEDOFF]: 'Sawed-Off',
    [WEAPON_TYPE.SCAR20]: 'SCAR-20',
    [WEAPON_TYPE.SG556]: 'SG%20553',
    [WEAPON_TYPE.SSG08]: 'SSG%2008',
    [WEAPON_TYPE.TEC9]: 'Tec-9',
    [WEAPON_TYPE.UMP45]: 'UMP-45',
    [WEAPON_TYPE.USP_SILENCER]: 'USP-S',
    [WEAPON_TYPE.XM1014]: 'XM1014',
    [WEAPON_TYPE.KNIFE_BUTTERFLY]: '★%20Butterfly%20Knife',
    [WEAPON_TYPE.KNIFE_KARAMBIT]: '★%20Karambit',
    [WEAPON_TYPE.KNIFE_FALCHION]: '★%20Falchion%20Knife',
    [WEAPON_TYPE.KNIFE_CSS]: '★%20Classic%20Knife',
    [WEAPON_TYPE.KNIFE_SURVIVAL_BOWIE]: '★%20Bowie%20Knife',
    [WEAPON_TYPE.KNIFE_TACTICAL]: '★%20Huntsman%20Knife',
    [WEAPON_TYPE.KNIFE_OUTDOOR]: '★%20Nomad%20Knife',
    [WEAPON_TYPE.KNIFE_CORD]: '★%20Paracord%20Knife',
    [WEAPON_TYPE.KNIFE_FLIP]: '★%20Flip%20Knife',
    [WEAPON_TYPE.KNIFE_CANIS]: '★%20Survival%20Knife',
    [WEAPON_TYPE.KNIFE_SKELETON]: '★%20Skeleton%20Knife',
    [WEAPON_TYPE.KNIFE_GUT]: '★%20Gut%20Knife',
    [WEAPON_TYPE.KNIFE_WIDOWMAKER]: '★%20Talon%20Knife',
    [WEAPON_TYPE.KNIFE_STILETTO]: '★%20Stiletto%20Knife',
    [WEAPON_TYPE.KNIFE_PUSH]: '★%20Shadow%20Daggers',
    [WEAPON_TYPE.KNIFE_URSUS]: '★%20Ursus%20Knife',
    [WEAPON_TYPE.BAYONET]: '★%20Bayonet',
    [WEAPON_TYPE.KNIFE_M9_BAYONET]: '★%20M9%20Bayonet',
    [WEAPON_TYPE.KNIFE_GYPSY_JACKKNIFE]: '★%20Navaja%20Knife',
};

export const STEAM_WEAPON_WEAR = {
    [ITEM_WEAR.BATTLE_SCARRED]: 'Battle-Scarred',
    [ITEM_WEAR.FACTORY_NEW]: 'Factory%20New',
    [ITEM_WEAR.FIELD_TESTED]: 'Field-Tested',
    [ITEM_WEAR.MINIMAL_WEAR]: 'Minimal%20Wear',
    [ITEM_WEAR.WELL_WORN]: 'Well-Worn',
};

export const NEW_ROW_WEAPONS_FIRST = 'INSERT INTO weapons VALUES ( "';
// eslint-disable-next-line max-len
export const NEW_ROW_WEAPONS_SECOND = '", "0", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1", "0", "0", "0", "0", "", "-1")';

export enum WEAPON_GROUP {
    HEAVY = 'heavy',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    KNIFES = 'knifes',
    PISTOLS = 'pistols',
    PMS = 'pms',
    RIFLES = 'rifles',
}
export const WEAPONS_FOR_GROUPS = {
    [WEAPON_GROUP.KNIFES]: KNIFES,
    [WEAPON_GROUP.PISTOLS]: [
        WEAPON_TYPE.USP_SILENCER,
        WEAPON_TYPE.GLOCK,
        WEAPON_TYPE.P250,
        WEAPON_TYPE.FIVESEVEN,
        WEAPON_TYPE.TEC9,
        WEAPON_TYPE.DEAGLE,
        WEAPON_TYPE.ELITE,
        WEAPON_TYPE.REVOLVER,
    ],
    [WEAPON_GROUP.HEAVY]: [
        WEAPON_TYPE.NOVA,
        WEAPON_TYPE.XM1014,
        WEAPON_TYPE.SAWEDOFF,
        WEAPON_TYPE.MAG7,
        WEAPON_TYPE.M249,
        WEAPON_TYPE.NEGEV,
    ],
    [WEAPON_GROUP.PMS]: [
        WEAPON_TYPE.MP9,
        WEAPON_TYPE.MAC10,
        WEAPON_TYPE.MP7,
        WEAPON_TYPE.MP5SD,
        WEAPON_TYPE.UMP45,
        WEAPON_TYPE.P90,
        WEAPON_TYPE.BIZON,
    ],
    [WEAPON_GROUP.RIFLES]: [
        WEAPON_TYPE.FAMAS,
        WEAPON_TYPE.GALILAR,
        WEAPON_TYPE.SSG08,
        WEAPON_TYPE.AUG,
        WEAPON_TYPE.SG556,
        WEAPON_TYPE.AWP,
        WEAPON_TYPE.G3SG1,
        WEAPON_TYPE.SCAR20,
        WEAPON_TYPE.M4A1,
        WEAPON_TYPE.M4A1_SILENCER,
        WEAPON_TYPE.AK47,
    ],
};
