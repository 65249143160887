import { Button } from '@mui/material';
import {
    ITEM_RARITY,
    ITEM_WEAR,
    ITEM_WEAR_SHORT,
    KNIFES,
    WEAPON_TYPE,
} from '../../../types/enums';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Coin from '../Coin';
import ItemImage from '../ItemImage';
import React from 'react';
import classNames from 'classnames';
import styles from './EquipmentSkinPreview.module.scss';
import weaponBasicData from '../../weaponBasicData';

interface IProps {
    equip?: boolean | (() => void);
    equiped?: boolean;
    float?: number;
    icon: string;
    id?: string;
    isNameVisible?: boolean;
    isPriceVisible?: boolean;
    isSmall?: boolean;
    isStatTrack?: boolean;
    name?: string;
    noAnimation?: boolean;
    onClick?: () => void;
    price?: number;
    rarity: ITEM_RARITY;
    selected?: boolean;
    weaponType: WEAPON_TYPE;
    wear?: ITEM_WEAR;
}

// eslint-disable-next-line complexity
const EquipmentSkinPreview = ({
    rarity,
    icon,
    isNameVisible = true,
    isPriceVisible = true,
    isSmall,
    isStatTrack = false,
    name = '-',
    noAnimation = false,
    price = 0,
    selected,
    weaponType,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onClick = () => {},
    equiped = false,
    equip = false,
    wear,
}: IProps) => (
    <div className={classNames(styles.itemWrapper, isSmall && styles.smallItemWrapper, selected && styles.selected)} onClick={onClick}>
        <ItemImage
            icon={icon}
            isKnife={KNIFES.includes(weaponType)}
            noAnimation={noAnimation}
            rarity={rarity}
        />
        <div className={classNames(styles.itemPreview, isSmall && styles.smallItemPreview)}>
            <div className={classNames(styles.descTop, isSmall && styles.smallDescTop)}>
                {equip &&
                    <Button
                        size='small'
                        sx={{ fontSize: '10px', margin: '8px', padding: '3px' }}
                        variant="contained"
                    >
                        <div style={{ alignItems: 'center', display: 'flex' }}>
                            <>WYPOSAZ</>
                            <AddIcon sx={{ height: '15px', marginLeft: '3px', width: '15px' }} />
                        </div>
                    </Button>
                }
                <div className={styles.values}>
                    {equiped && <CheckCircleIcon color="success" sx={{ display: { md: 'flex', xs: 'none' }, height: '20px' }} />}
                    {isStatTrack ? <div style={{ color: '#CF6A32' }}>ST</div> : ''}
                </div>
                <div>
                    {isPriceVisible &&
                    <div style={{ display: 'flex', marginTop: '10px' }}>
                        <div style={{ marginRight: '2px' }}>{price}</div>
                        <Coin color="#e4ac03" height="21px" width="21px" />
                    </div>
                    }
                    {!!wear && (
                        <div className={styles.itemWear}>{ITEM_WEAR_SHORT[wear]}</div>
                    )}
                </div>
            </div>
            {isNameVisible && <div className={classNames(styles.descBottom, isSmall && styles.smallDescBottom)}>
                {!isSmall && (
                    <div>{weaponBasicData[weaponType].name.pl}</div>
                )}
                <div className={classNames(styles.itemName, isSmall && styles.smallItemName)}>{name}</div>
            </div>}
        </div>
    </div>
);

export default EquipmentSkinPreview;
