import {
    IChestsGroup,
    IChestsGroupItem,
} from '../../../../types/ChestsGroup';
import Button from '@mui/material/Button';
import CategoryItem from './components/CategoryItem/CategoryItem';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import styles from './ChestsGroups.module.scss';

const ChestsGroups = () => {
    const [categories, setCategories] = useState<IChestsGroup[]>([]);
    const [newCategoryName, setNewCategoryName] = useState('');

    useEffect(
        () => {
            axios.get('/api/get-chests-categories').then(({ data }) => {
                setCategories(data.categories);
            });
        },
        [],
    );

    const handleAddCategory = useCallback(
        () => {
            setNewCategoryName('');
            setCategories(prev => [
                {
                    items: [],
                    name: newCategoryName,
                },
                ...prev,
            ]);
        },
        [newCategoryName],
    );

    const moveCategory = (index: number, moveUp?: boolean) => {
        setCategories(prevState => {
            const copiedItems = [...prevState];
            if (moveUp) {
                if (index === 0) {
                    return copiedItems;
                }
                copiedItems.splice(index - 1, 0, copiedItems.splice(index, 1)[0]);
            } else {
                if (index === copiedItems.length - 1) {
                    return copiedItems;
                }
                copiedItems.splice(index + 1, 0, copiedItems.splice(index, 1)[0]);
            }
            return copiedItems;
        });
    };

    const removeCategory = (index: number) => {
        setCategories(prevState => {
            const copiedItems = [...prevState];

            copiedItems.splice(index, 1);

            return copiedItems;
        });
    };

    const handleChestItemOrder = useCallback(
        (chestIndex: number, items: IChestsGroupItem[]) => {
            setCategories(prevState => {
                const copiedCategories = [...prevState];
                copiedCategories[chestIndex].items = items;

                return copiedCategories;
            });
        },
        [],
    );

    const handleSave = useCallback(
        () => {
            const mappedCategories = categories.map((category, index) => ({
                ...category,
                index,
            }));
            axios.post('/api/save-chest-categories', { categories: mappedCategories });
        },
        [categories],
    );

    return (
        <div className={styles.wrapper}>
            <div
                className={styles.addCategory}
            >
                <div style={{ width: '100%' }}>
                    <Button
                        onClick={handleAddCategory}
                        sx={{ margin: '10px 10px 0 10px' }}
                        variant="contained"
                    >
                        Add category
                    </Button>
                    <TextField
                        color="primary"
                        focused
                        onChange={(data) => { setNewCategoryName(data.target.value); }}
                        placeholder="Nazwa nowej kategorii"
                        value={newCategoryName}
                    />
                </div>
                <div>
                    <Button
                        color='success'
                        onClick={handleSave}
                        sx={{ margin: '10px 10px 0 10px' }}
                        variant="contained"
                    >
                        SaveAll
                    </Button>
                </div>
            </div>
            {categories.map((category, index) => (
                <div
                    className={styles.category}
                    key={category.name}
                >
                    <CategoryItem
                        category={category}
                        categoryIndex={index}
                        changeChestsOrder={handleChestItemOrder}
                        moveCategory={moveCategory}
                        removeCategory={removeCategory}
                    />
                </div>
            ))}
        </div>
    );
};

export default ChestsGroups;
