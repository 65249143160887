import { IChestsGroupItem } from '../../../../../../types/ChestsGroup';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import React from 'react';
import styles from './ChestItem.module.scss';

interface IChestItem {
    index: number;
    item: IChestsGroupItem;
    moveItem: (index: number, moveUp: boolean) => void;
    removeItem: (index: number) => void;
}

const ChestItem = ({
    index,
    item,
    moveItem,
    removeItem,
}: IChestItem) => {

    return (
        <div className={styles.wrapper}>
            <div className={styles.actionsIcons}>
                <div><ArrowUpwardIcon onClick={() => moveItem(index, true)} /></div>
                <div><ArrowDownwardIcon onClick={() => moveItem(index, false)} /></div>
                <div><HighlightOffIcon onClick={() => removeItem(index)} /></div>
            </div>
            <div className={styles.chestName}>
                {item.name}
            </div>
        </div>
    );
};

export default ChestItem;
