import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
} from '@mui/material';
import { WEAPON_TYPE } from '../../types/enums';
import { debounce } from 'lodash';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import React, {
    useCallback,
    useState,
} from 'react';
import SearchIcon from '@mui/icons-material/Search';
import weaponBasicData from '../../shared/weaponBasicData';

interface IProps {
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    setWeaponTypeValue: React.Dispatch<React.SetStateAction<string>>;
}

const WikiSearchField = ({ setSearchValue, setWeaponTypeValue }: IProps) => {
    const [weaponType, setWeaponType] = useState<string>('');

    const debouncedSetSearchValue = debounce(
        (value: string) => setSearchValue(value),
        300,
    );

    const handleWeaponTypeChange = useCallback(
        ({ target }: SelectChangeEvent) => {
            setWeaponType(target.value);
            setWeaponTypeValue(target.value);
        },
        [setWeaponTypeValue],
    );

    return (
        <>
            <Paper
                component="div"
                sx={{ alignItems: 'center', display: 'flex', gap: '10px', p: '2px 4px' }}
            >
                <FormControl size="small" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-label">Typ</InputLabel>
                    <Select
                        id="demo-simple-select"
                        inputProps={{ maxLength: 10 }}
                        label="Age"
                        labelId="demo-simple-select-label"
                        onChange={handleWeaponTypeChange}
                        value={weaponType}
                    >
                        {Object.entries(WEAPON_TYPE).map(([value, key]) => (
                            <MenuItem
                                key={value}
                                sx={{ textTransform: 'uppercase' }}
                                value={key}
                            >
                                {value !== '' ? weaponBasicData[key].name.pl : ''}
                                {/* {value} */}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Paper>
            <Paper
                component="div"
                sx={{ alignItems: 'center', display: 'flex', p: '2px 4px', width: 400 }}
            >
                <InputBase
                    inputProps={{ 'aria-label': 'search' }}
                    onChange={(e) => debouncedSetSearchValue(e.target.value)}
                    placeholder="Search"
                    sx={{ flex: 1, ml: 1 }}
                />
                <Divider orientation="vertical" sx={{ height: 28, m: 0.5 }} />
                <Box alignItems="center" display="flex" justifyContent="center" sx={{ p: 2 }}>
                    <SearchIcon />
                </Box>
            </Paper>
        </>

    );
};

export default WikiSearchField;

