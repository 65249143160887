import { ITEM_RARITY } from '../../../../types/enums';
import { IWeaponTemplate } from '../../../../types/WeaponTemplate';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import TemplateModal from '../TemplateModal/TemplateModal';
import axios from 'axios';
import classNames from 'classnames';
import styles from './Templates.module.scss';

const MenuLeft = () => {
    const [isOpenedModal, setIsOpenedModal] = useState(false);
    const [templateId, setTemplateId] = useState('');
    const [templates, setTemplates] = useState<IWeaponTemplate[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');

    const handleModalState = useCallback(
        (state: boolean, id?: string) => {
            setIsOpenedModal(state);
            if (id && id !== '') {
                setTemplateId(id);
            } else {
                setTemplateId('');
            }
        },
        [],
    );

    useEffect(
        () => {
            axios.get(`/api/get-templates?limit=100&search=${searchQuery}`).then(({ data }) => {
                setTemplates(data.items);
            });
        },
        [searchQuery],
    );

    const classes = (itemRarity: ITEM_RARITY) => ({
        [ITEM_RARITY.CONSUMER_GRADE]: styles.classWhite,
        [ITEM_RARITY.INDUSTRIAL_GRADE]: styles.classLightBlue,
        [ITEM_RARITY.MIL_SPEC]: styles.classBlue,
        [ITEM_RARITY.CONTRABAND]: styles.classPurple,
        [ITEM_RARITY.RESTRICTED]: styles.classPink,
        [ITEM_RARITY.COVERT]: styles.classRed,
        [ITEM_RARITY.CLASSIFIED]: styles.classGold,
    })[itemRarity];

    const weaponPreview = (item: IWeaponTemplate) => (
        <div className={styles.itemWrapper}>
            <div className={styles.backdrop}>
                <div className={classNames(
                    styles.classColor,
                    classes(item.rarity),
                )} />
                <div className={styles.backdropBlur} />
            </div>
            <div className={styles.itemPreview}>
                <img
                    alt="dupa"
                    className={styles.itemImage}
                    src={item.itemIcon}
                />
            </div>
            <div className={styles.itemData}>
                <div>{item.enName}</div>
                <div>{item.plName}</div>
            </div>
        </div>
    );

    return (
        <div className={styles.wrapper}>
            Templates
            <input onChange={(e) => { setSearchQuery(e.target.value); }} type="text" />
            <div className={styles.itemsList}>
                {templates.map((template, index) => (
                // eslint-disable-next-line no-console
                    <div key={index} onClick={() => handleModalState(true, template._id)}>
                        {weaponPreview(template)}
                    </div>
                ))}
            </div>

            <TemplateModal
                handleModalState={handleModalState}
                isOpen={isOpenedModal}
                templateId={templateId}
            />
        </div>
    );
};

export default MenuLeft;
