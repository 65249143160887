import { IChest } from '../../../../types/chest';
import Button from '@mui/material/Button';
import ChestModal from '../../../Modals/ChestModal';
import ChestPreview from '../../../../shared/components/ChestPreview';
import React, {
    useEffect,
    useState,
} from 'react';
import axios from 'axios';
import styles from './Chests.module.scss';

const MenuLeft = () => {
    const [chests, setChests] = useState<IChest[]>([]);
    const [openChestModal, setOpenChestModal] = React.useState(false);
    const [chestId, setChestId] = React.useState<string | null>(null);

    useEffect(
        () => {
            axios.get('/api/get-chests').then(({ data }) => {
                setChests(data.items);
            });
        },
        [],
    );

    useEffect(
        () => {
            if (!openChestModal) {
                setChestId(null);
            }
        },
        [openChestModal],
    );

    const onChestClick = (id: string) => {
        setChestId(id);
        setOpenChestModal(true);
    };

    return (
        <div className={styles.wrapper}>
            {openChestModal && (
                <ChestModal
                    chestId={chestId}
                    open={openChestModal}
                    setOpen={setOpenChestModal}
                />
            )}
            <Button onClick={() => setOpenChestModal(true)} sx={{ margin: '10px 10px 0 10px' }} variant="contained">
                Create Chest
            </Button>
            <div className={styles.chestsWrapper}>
                {chests.map((chest) => (
                    <ChestPreview
                        chest={chest}
                        key={chest.id}
                        onChestClick={() => onChestClick(chest.id)}
                    />
                ))}
            </div>
        </div>
    );
};

export default MenuLeft;
