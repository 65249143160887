/* eslint-disable no-console */
import { IWeaponTemplate } from '../../../types/WeaponTemplate';
import {
    useNavigate,
    useParams,
} from 'react-router-dom';
import Button from '../Button';
import Divider from '../Divider';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import SkinInfo from '../SkinInfo';
import axios from 'axios';
import styles from './SkinDetails.module.scss';

const SkinDetails = () => {
    const [, setLoading] = useState(true);
    const [data, setData] = useState<IWeaponTemplate>({} as IWeaponTemplate);
    const navigate = useNavigate();
    const { caseId, itemId } = useParams();

    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .get(`/api/get-template/${itemId}`)
            .then((res) => {
                setData(res.data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    }, [itemId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className={styles.wrapper}>
            <div style={{ width: '100%' }}>
                <Button name="POWRÓT" onClick={() => navigate(`/chests/${caseId}`)} />
                {data ?
                    <div className={styles.header}>
                        <Divider name="DETALE PRZEDMIOTU" nameColor="#fff" />
                    </div>
                    :
                    null
                }
                <div>
                    <SkinInfo itemIcon={data.itemIcon} plName={data.plName} price={data.price} rarity={data.rarity} />
                </div>
            </div>
        </div>
    );
};

export default SkinDetails;
