import Logo from '../Logo/Logo';
import React from 'react';
import classNames from 'classnames';
import styles from './Loader.module.scss';

interface ILoader {
    customSize?: number;
    isSmall?: boolean;
}

const Loader = ({
    customSize,
    isSmall,
}: ILoader) => (
    <div className={styles.wrapper}>
        <div
            className={classNames(styles.logoWrapper, isSmall && styles.small)}
            style={customSize ? { width: `${customSize}px` } : {}}
        >
            <Logo />
        </div>
        <div className={styles.backgroundWrapper}>
            <div className={styles.background} />
        </div>
    </div>
);

export default Loader;
