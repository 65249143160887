import {
    Dialog,
    DialogContent,
    DialogTitle,
} from '@mui/material';
import { IChest } from '../../../../../../types/chest';
import { IChestsGroupItem } from '../../../../../../types/ChestsGroup';
import BackDrop from '../../../../../BackDrop';
import React, {
    useEffect,
    useState,
} from 'react';
import axios from 'axios';
interface IChestsModal {
    addChestToCategory: (chest: IChestsGroupItem) => void;
    closeModal: () => void;
    isOpen: boolean;
}

const ChestsModal = ({
    addChestToCategory,
    closeModal,
    isOpen,
}: IChestsModal) => {
    const [chests, setChests] = useState<IChest[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(
        () => {
            isOpen &&
            axios.get('/api/get-chests').then(({ data }) => {
                setChests(data.items);
                setLoading(false);
            });
        },
        [isOpen],
    );

    return (
        <div>
            <Dialog
                maxWidth="xl"
                onClose={closeModal}
                open={isOpen}
            >
                <BackDrop loading={loading} />
                <DialogTitle>Dodaj skrzynie</DialogTitle>
                <DialogContent>
                    {
                        chests.map(chest => (
                            <div
                                key={chest.id}
                                onClick={() => addChestToCategory({
                                    id: chest.id,
                                    name: chest.name,
                                })}
                            >
                                {chest.name} - {chest.price}
                            </div>
                        ))
                    }

                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ChestsModal;
