import { IItemPreview } from '../../../types/chest';
import { KNIFES } from '../../../types/enums';
import ItemImage from '../ItemImage';
import React from 'react';
import classNames from 'classnames';
import styles from './SkinPreview.module.scss';
import weaponBasicData from '../../weaponBasicData';

// eslint-disable-next-line complexity
const SkinPreview = ({
    chance,
    icon,
    isSmall,
    maxPrice,
    minPrice,
    name,
    noBorder,
    rarity,
    weaponType,
}: IItemPreview) => (
    <div className={classNames(styles.itemWrapper, isSmall && styles.smallItemWrapper, noBorder && styles.noBorder)}>
        <ItemImage
            icon={icon}
            isKnife={KNIFES.includes(weaponType)}
            rarity={rarity}
        />
        <div className={classNames(styles.itemPreview, isSmall && styles.smallItemPreview)}>
            <div className={classNames(styles.descTop, isSmall && styles.smallDescTop)}>
                <div className={styles.values}>
                    <div>
                        <div>MAX:</div>
                        <div>MIN:</div>
                    </div>
                    <div>
                        <div>{maxPrice}</div>
                        <div>{minPrice}</div>
                    </div>
                </div>
                <div>
                    <div>szansa</div>
                    <div>{chance}%</div>
                </div>
            </div>
            <div className={classNames(styles.descBottom, isSmall && styles.smallDescBottom)}>
                {!isSmall && (
                    <div>{weaponBasicData[weaponType].name.pl}</div>
                )}
                <div className={classNames(styles.itemName, isSmall && styles.smallItemName)}>{name}</div>
            </div>
        </div>
    </div>
);

export default SkinPreview;
