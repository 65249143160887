/* eslint-disable no-console */
import { IWeaponTemplate } from '../../types/WeaponTemplate';
import { useNavigate } from 'react-router-dom';
import { wikiContainerStyle } from './wikiStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import BackDrop from '../BackDrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import WikiTable from '../WikiTable';
import axios from 'axios';

const Wiki = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<IWeaponTemplate[]>([]);
    const [loading, setLoading] = useState(true);
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [weaponTypeValue, setWeaponTypeValue] = useState('');

    const fetchData = useCallback(() => {
        setLoading(true);
        axios
            .get('/api/get-templates', { params: { limit, page, search: searchValue, weaponType: weaponTypeValue } })
            .then((res) => {
                setData(res.data.items);
                setLimit(res.data.limit);
                setPage(res.data.page);
                setTotalItems(res.data.totalItems);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    }, [limit, page, searchValue, weaponTypeValue]);

    useEffect(() => {
        fetchData();
    }, [fetchData, limit, page]);

    return (
        <div>
            <BackDrop loading={loading} />
            <Container maxWidth="xl" sx={wikiContainerStyle}>
                <Box sx={{ width: '100%' }}>
                    <Button onClick={() => navigate('/')} startIcon={<ArrowBackIosIcon />} variant="contained">
                        <>STRONA GŁÓWNA</>
                    </Button>
                    <Divider
                        flexItem
                        orientation="horizontal"
                        sx={{ color: '#fff', fontFamily: 'stratumBold', fontSize: '22px', margin: '20px 0' }}
                    >
                        <>WIKI</>
                    </Divider>
                    <Box sx={{ marginTop: '20px' }}>
                        <WikiTable
                            items={data}
                            limit={limit}
                            loading={loading}
                            page={page}
                            setLimit={setLimit}
                            setPage={setPage}
                            setSearchValue={setSearchValue}
                            setWeaponTypeValue={setWeaponTypeValue}
                            totalItems={totalItems}
                        />
                    </Box>
                </Box>
            </Container>
        </div>
    );
};

export default Wiki;
