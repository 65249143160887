import { createTheme } from '@mui/material/styles';

const scrollbarStyles = {
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
        backgroundColor: '#959595',
        width: 10,
    },
    '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
        backgroundColor: '#2b2b2b',
    },
    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        backgroundColor: '#333232',
        minHeight: 24,
    },
    '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
        backgroundColor: '#959595',
    },
    '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
        backgroundColor: '#959595',
    },
    '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#1f1e1e',
    },
    scrollbarColor: '#333232 #2b2b2b',
};

export const darkTheme = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'stratumBold',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#fff',
                    color: '#121212',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    ...scrollbarStyles,
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    color: '#000',
                    fontFamily: 'stratumBold',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontFamily: 'stratumBold',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: '#000',
                    fontFamily: 'stratumBold',
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    ...scrollbarStyles,
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    ...scrollbarStyles,
                },
            },
        },
    },
    palette: {
        mode: 'dark',
        primary: {
            main: '#121212',
        },
    },
});
// input-MuiOutlinedInput-input
export const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#121212',
        },
    },
});
