import Logo from '../Logo/Logo';
import React from 'react';

interface IProps {
    color?: string;
    height?: string;
    width?: string;
}

const Coin = ({
    color = 'gold',
    height = '18px',
    width = '18px',
}: IProps) => {
    return (
        <div style={{ color, height, width }}>
            <Logo />
        </div>
    );
};

export default Coin;
