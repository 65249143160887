import { IChest } from '../../types/chest';
import { IChestsGroup } from '../../types/ChestsGroup';
import { useNavigate } from 'react-router-dom';
import ChestPreview from '../../shared/components/ChestPreview';
import Divider from '../../shared/components/Divider';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import axios from 'axios';

const Chests = () => {
    const navigate = useNavigate();
    const [, setLoading] = useState(true);
    const [categories, setCategories] = useState<IChestsGroup[]>([]);

    const fetchData = useCallback(() => {
        setLoading(true);

        axios.get('/api/get-chests-categories')
            .then(({ data }) => {
                setLoading(false);
                setCategories(data.categories);
            })
            .catch(() => {
                setLoading(false);
                console.error('Something went wrong');
            });
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div>
                {categories.map((category, index) => (
                    <div
                        key={index}
                        style={{ width: '100%' }}
                    >
                        <Divider font="stratumBold" name={category.name} nameColor="#fff" />
                        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            {category.items.map((item, itemIndex) => (
                                <div
                                    key={itemIndex}
                                >
                                    <ChestPreview
                                        chest={item as IChest}
                                        onChestClick={() => navigate(`/chests/${item.id}`)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Chests;
