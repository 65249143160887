import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface IProps {
    acceptButtonTitle: string;
    content: string;
    onSubmit: () => void;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
}

const ConfirmDialog = ({ acceptButtonTitle, content, onSubmit, open, setOpen, title }: IProps) => {

    return (
        <Dialog
            aria-describedby="alert-dialog-description"
            aria-labelledby="alert-dialog-title"
            onClose={() => setOpen(false)}
            open={open}
        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={() => setOpen(false)}>Anuluj</Button>
                <Button autoFocus onClick={() => onSubmit()}>{acceptButtonTitle}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
